
import { useState, useRef, useEffect } from 'react'

const TimePicker = ({ label, name, value, onChange }: any) => {
    const [showPicker, setShowPicker] = useState(false)
    const [time, setTime] = useState(value || "")
    const pickerRef: any = useRef(null)

      const isDark=localStorage.getItem('theme')
      useEffect(()=>{
        if(isDark ==="dark"){
         document.documentElement.classList.add("dark");
        }
        else{
         document.documentElement.classList.remove("dark");
        }
         },[isDark,showPicker])
         
    const hours = Array.from(Array(12).keys())
      .map((i) => (i === 0 ? 12 : i))
      .map((h) => h.toString().padStart(2, "0"));
    const minutes = ['00', '15', '30', '45']
    const periods = ['AM', 'PM']

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (
                pickerRef.current &&
                !pickerRef.current.contains(event.target)
            ) {
                setShowPicker(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [pickerRef])

    const handleTogglePicker = (e: any) => {
        e.preventDefault()
        setShowPicker(!showPicker)
    }
    const convertTo12HourFormat = (time: any) => {
        const [hour24, minute] = time.split(':')
        const hour = parseInt(hour24, 10)
        const period = hour >= 12 ? 'PM' : 'AM'
        const hour12 = hour % 12 === 0 ? 12 : hour % 12
        return `${hour12.toString().padStart(2, '0')}:${minute} ${period}`
    }

    const convertTo24HourFormat = (time12: any) => {
        const [timePart, period] = time12.split(' ')
        let [hour, minute] = timePart.split(':')
        hour = parseInt(hour, 10)
        if (period === 'PM' && hour !== 12) {
            hour += 12
        } else if (period === 'AM' && hour === 12) {
            hour = 0
        }
        return `${hour.toString().padStart(2, '0')}:${minute}`
    }

    useEffect(() => {
        if (value) {
            setTime(convertTo12HourFormat(value))
        }
    }, [value])

    const handleTimeChange = (part: any, newValue: any) => {
        const [timePart, period] = time.split(' ')
        let [hour, minute] = timePart.split(':')
        let newTime = ''

        if (part === 'hour') {
            newTime = `${newValue}:${minute} ${period}`
        } else if (part === 'minute') {
            newTime = `${hour}:${newValue} ${period}`
        } else if (part === 'period') {
            newTime = `${hour}:${minute} ${newValue}`
        }

        setTime(newTime)
        const convertedTime = convertTo24HourFormat(newTime)
        onChange({ target: { name, value: convertedTime } })
    }

    const handleManualInputChange = (e: any) => {
        const newTime = e.target.value
        const formattedTime = convertTo12HourFormat(newTime)
        setTime(formattedTime)
        onChange({ target: { name, value: newTime } })
    }

    return (
        <div className="relative" ref={pickerRef}>
            <label className="text-[14px] grid  gap-4 items-center">
                <span className="font-semibold">{label}</span>
                <div className="hide-time-selection">
                    <input
                        type="time"
                        name={name}
                        value={convertTo24HourFormat(time)}
                        onClick={handleTogglePicker}
                        onChange={handleManualInputChange}
                        className={`rounded border-slate-300
                        col-span-2 input input-md h-11 w-80 dark:bg-gray-700 dark:text-gray-100`}
                        style={{
                            colorScheme:isDark ==="dark" ? 'dark':''
                        }}
                    />
                </div>
            </label>
            {showPicker && (
                <div className="absolute bg-white dark:bg-gray-700 shadow-lg border rounded mt-1 z-10 w-50 max-h-52 overflow-y-auto left-1/2 transform -translate-x-1/2">
                    <div className="flex ">
                        <div className="flex flex-col p-2">
                            {hours.map((hour) => (
                                <div
                                    key={hour}
                                    className={`cursor-pointer px-4 py-2 hover:bg-gray-200  dark:hover:text-black  ${
                                        time.split(' ')[0].split(':')[0] ===
                                        hour
                                            ? 'bg-blue-500 text-white'
                                            : ''
                                    }`}
                                    onClick={() =>
                                        handleTimeChange('hour', hour)
                                    }
                                >
                                    {hour}
                                </div>
                            ))}
                        </div>
                        <div className="flex flex-col p-2">
                            {minutes.map((minute) => (
                                <div
                                    key={minute}
                                    className={`cursor-pointer px-4 py-2 hover:bg-gray-200  dark:hover:text-black  ${
                                        time.split(' ')[0].split(':')[1] ===
                                        minute
                                            ? 'bg-blue-500 text-white'
                                            : ''
                                    }`}
                                    onClick={() =>
                                        handleTimeChange('minute', minute)
                                    }
                                >
                                    {minute}
                                </div>
                            ))}
                        </div>
                        <div className="flex flex-col p-2">
                            {periods.map((period) => (
                                <div
                                    key={period}
                                    className={`cursor-pointer px-4 py-2 hover:bg-gray-200  dark:hover:text-black  ${
                                        time.split(' ')[1] === period
                                            ? 'bg-blue-500 text-white'
                                            : ''
                                    }`}
                                    onClick={() =>
                                        handleTimeChange('period', period)
                                    }
                                >
                                    {period}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default TimePicker
