import React, { useEffect }  from "react";
import NavigationItem from "./NavigationItem";
import { NAVIGATION_DEMO_2 } from "data/navigation";
import { useMediaQuery } from "@react-hook/media-query";

function Navigation() {
  const isDesktop = useMediaQuery("(min-width: 641px)");
   const [userDataString, setUserDataString] = React.useState<string | null>(null);
  const [SignupData, setSignupData] = React.useState<string | null>(null);
 const userData = localStorage.getItem('userData');
    const signupData = localStorage.getItem('SignupData');
  useEffect(() => {
   
    setUserDataString(userData);
    setSignupData(signupData);
  }, [userData,signupData]);
  console.log("enternav",userData,signupData)
  return (
    <>
      {isDesktop && (
        <ul className="nc-Navigation flex items-center">
          {NAVIGATION_DEMO_2
            .filter((item) => (item.name!=="Menu" && item.name !== "Order History" && item.name !== "Login" && item.name !== "Sign Up" && item.name !== "Payment Method"&& item.name !== "Profile"&& item.name !== "Sign Out")) // Filter out "About Us" item
            .map((item) => (
              <NavigationItem key={item.id} menuItem={item}  />
            ))}
        </ul>
      )}
    </>
  );
}

export default Navigation;
