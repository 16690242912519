import BaseService from './BaseService';
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

const ApiService = {
  fetchData<Response = unknown, Request = Record<string, unknown>>(
    param: AxiosRequestConfig<Request>
  ) {
    return new Promise<AxiosResponse<Response>>((resolve, reject) => {
      BaseService(param)
        .then((response: AxiosResponse<Response>) => {
          if (response.status === 504) {
            // Handle a 504 Gateway Timeout response here
            const errorMessage = 'Gateway Timeout (504): Please try again later.';
            reject(new Error(errorMessage));
            return(
                ('Try Again')
            )
          } else {
            const errorMessage = 'Gateway Timeout (504): Please try again later.';
            resolve(response);
            return(
                ('Try Again')
            )
          }
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default ApiService;
