export type AppConfig = {
    apiPrefix: string
    authenticatedEntryPath: string
    unAuthenticatedEntryPath: string
    tourPath: string
    locale: string
    enableMock: boolean
}
// http://3.131.138.214:5000/api -- PROD

// DEV WEB: https://basilbackoffice-dev.com
// PRO WEB: https://basilbackoffice.com

// DEV S3: basilbackoffice-dev.com
// PRO S3: basilbackoffice.com

// DEV API: https://basil-backoffice-dev.com/api
// PRO API: https://basil-backoffice.com/api
// Load Balancer API: http://backoffice-lb-1095231419.us-east-2.elb.amazonaws.com/api

const appConfig: AppConfig = {
    apiPrefix: 'https://basil-backoffice.com/api',
    //apiPrefix:'http://localhost:5000/api',
    authenticatedEntryPath: '/',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
}

export default appConfig
