import React, { useEffect } from "react";
import { format } from "date-fns";
import { Product } from "@/data/data";
import { useMemo, useState } from "react";
interface Order {
  orderNo: number;
  date: string;
  totalAmount: number;
  subTotal: number;
  tax: number;
  tip: number;
  status: string;
  orderItems: any;
}
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  order: Order; // Assuming Order interface is defined
  handlereOrder: any;
}

const OrderDetails: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  order,
  handlereOrder,
}) => {
  const [storedOrderFee, setStoredOrderFee] = useState<number>(0);
  const [storedFeeLabel, setStoredFeeLabel] = useState<string>("");
  useEffect(() => {
    const storedOrderFe = localStorage.getItem("orderFee");
    const storedOrderFeeLabell = localStorage.getItem("orderFeeLabel");
    setStoredFeeLabel(storedOrderFeeLabell || "");
    setStoredOrderFee(Number(storedOrderFe) || 0);
  });
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-lg p-6 w-full max-w-sm h-auto overflow-y-auto max-h-full "
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-2xl font-semibold mb-4">
          Order No: {order.orderNo}
        </h2>
        <p className="text-s">Status: {order.status}</p>
        <p className="text-xs">
          (Date: {format(new Date(order.date), "MMM dd, yyyy hh:mm a")})
        </p>
        <h3 className="text-lg font-semibold mt-4 mb-2">Items:</h3>
        <ul className="list-disc pl-5">
          {/* {order.orderItems.map((item: any, index: any) => (
            
            <li key={index} className="mb-4">
              <div className="flex justify-between">
                <div>
                  <span className="font-bold">{item.item.name}</span> -{" "}
                  {item.quantity} Qty
                </div>
                <span className="font-bold">
                  ${item.item.onlinePrice.toFixed(2)}
                   
                </span>
              </div>
              <div className="pl-0">
                {item.orderItemModifiers.map(
                  // (modifierSet: any, setIndex: any) => (
                  //   <div key={setIndex}>
                  //     <ul className="list-disc pl-2">
                  //       {modifierSet.modifierSetModifierItems.map(
                  (modifierItem: any, itemIndex: any) => (
                    <h3
                      className="text-xs flex justify-between items-center"
                      key={itemIndex}
                    >
                      <span>{modifierItem.name}:</span>
                      {modifierItem.modifierItem.onlinePrice >= 0 && (
                        <span className="ml-auto">
                          ${modifierItem.modifierItem.onlinePrice.toFixed(2)}
                        </span>
                      )}
                    </h3>
                  )
                )}
              </div>
            </li>
          ))} */}
          {order.orderItems.map((item: any, index: any) => {
            let totalPrice =
              item.item.onlinePrice * item.quantity +
              (item.orderItemModifiers?.reduce(
                (acc: any, modifier: any) =>
                  acc + modifier.modifierItem.onlinePrice * item.quantity,
                0
              ) || 0);
            return (
              <li key={index} className="mb-4">
                <div className="flex justify-between">
                  <div>
                    <span className="font-bold">{item.item.name}</span> -{" "}
                    {item.quantity} Qty
                  </div>
                  <span className="font-bold">${item.price.toFixed(2)}</span>
                </div>
                <div className="pl-0">
                  {item.orderItemModifiers.map(
                    (modifierItem: any, itemIndex: any) => (
                      <h3
                        className="text-xs flex justify-between items-center"
                        key={itemIndex}
                      >
                        <span>{modifierItem.name}:</span>
                        {modifierItem.price >= 0 && (
                          <span className="ml-auto">
                            ${modifierItem.price.toFixed(2)}
                          </span>
                        )}
                      </h3>
                    )
                  )}
                </div>
              </li>
            );
          })}
        </ul>
        <div className="flex justify-between">
          <p className="text-sm">Sub Total:</p>
          <p className="text-sm text-right">${order.subTotal}</p>
        </div>
        <div className="flex justify-between">
          <p className="text-sm">Tax:</p>
          <p className="text-sm text-right">${order.tax.toFixed(2)}</p>
        </div>
        <div className="flex justify-between">
          <p className="text-sm">Tip:</p>
          <p className="text-sm text-right">${order.tip.toFixed(2)}</p>
        </div>
        <div className="flex justify-between">
          <p className="text-sm">{storedFeeLabel}:</p>
          <p className="text-sm text-right">${storedOrderFee.toFixed(2)}</p>
        </div>
        <div className="flex justify-between">
          <p className="mt-1 font-semibold">Total:</p>
          <p className="mt-1 font-semibold text-right">
            ${order.totalAmount.toFixed(2)}
          </p>
        </div>

        <div className="flex justify-end">
          <button
            className="mt-4 ml-2 bg-custom-green text-white font-normal py-2 px-4 rounded"
            onClick={() => handlereOrder(order)}
          >
            Reorder
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
