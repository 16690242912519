import React from "react";
import visa from "images/vis.png";
import master from "images/mastercard.png";
import Radio from "./Radio/Radio";
import { MdOutlineModeEdit } from "react-icons/md";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useLocation } from "react-router-dom";
interface CardProps {
  card: any;
  onSelect?: any;
  selected?: any;
  defaulChecked?: any;
  cardType: string; // Add other card types as needed
  cardHolderName: string;
  cardNumber: string;
  // expiryDate: string;
  //isPrimary: boolean;
  onEdit: () => void;
  onDelete: () => void;
}

const Card: React.FC<CardProps> = ({
  card,
  cardType,
  defaulChecked,
  cardHolderName,
  cardNumber,
  onEdit,
  onDelete,
  onSelect,
  selected,
}) => {
  const cardTypeIcon = {
    Visa: visa, // path to visa logo
    MasterCard: master, // path to mastercard logo
    //Amex: 'amex.png', // path to amex logo
  }[cardType];
  const location = useLocation();
  const merchantname=localStorage.getItem("merchantname");
  console.log(location.pathname,localStorage,"location");
  return (
    <div className="flex items-center justify-between border p-4 rounded-md mb-6">
      <div className="flex items-center gap 4">
        {selected !== undefined && (
          <Radio
            id="selectedCard"
            name="selectedCard"
            defaultChecked={defaulChecked}
            onChange={() => onSelect(card)}
            checked={selected}
          />
        )}
        <img
          src={cardTypeIcon}
          alt={`${cardType} logo`}
          className="w-12 mr-4"
        />
        <div>
          <div className="text-lg font-semibold">
            {cardHolderName} {cardNumber}
          </div>
          {/* {isPrimary && <span className="text-blue-600 bg-blue-100 px-2 py-1 rounded-md text-xs">Primary</span>} */}
          {/* <div className="text-sm text-gray-500">Expired {expiryDate}</div> */}
        </div>
      </div>
      <div className="flex space-x-2">
        {/* <button onClick={onDelete} className="text-red-500">Delete</button>
        <button onClick={onEdit} className="text-blue-500">Edit</button> */}
      </div>
      <div className="flex space-x-2">
        <button
          onClick={onDelete}
          type="button"
          className="text-red-500 hover:text-red-700"
        >
          {location.pathname === `/${merchantname}/account-savelists` && (
            <MdOutlineDeleteOutline
              style={{ color: "#7BB542", fontSize: "1.3rem" }}
            />
          )}
        </button>
        <button
          onClick={onEdit}
          type="button"
          className="text-blue-500 hover:text-blue-700"
        >
          {location.pathname === `/${merchantname}/account-savelists` && <MdOutlineModeEdit style={{ color: "#7BB542", fontSize: "1.3rem" }} />
          }
        </button>

      </div>
    </div>
  );
};

export default Card;
