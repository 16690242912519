import { productImgs } from "contains/fakeData";
import productVariantImg2 from "images/products/v2.jpg";
import productVariantImg3 from "images/products/v3.jpg";
import productVariantImg4 from "images/products/v4.jpg";
import productVariantImg5 from "images/products/v5.jpg";
import productVariantImg6 from "images/products/v6.jpg";
//
import productSport1 from "images/products/sport-1.png";
import productSport2 from "images/products/sport-2.png";
import productSport3 from "images/products/sport-3.png";
import productSport4 from "images/products/sport-4.png";
import productSport5 from "images/products/sport-5.png";
import productSport6 from "images/products/sport-6.png";
import productSport7 from "images/products/sport-7.png";
import productSport8 from "images/products/sport-8.png";
import { Dispatch, SetStateAction } from "react";

export interface ProductVariant {
  id: number;
  name: string;
  thumbnail?: string;
  color?: string;
  featuredImage: string;
}

interface Unit {
  id: number;
  name: string;
  createdBy: null | number;
  createdDate: null | string;
  modifiedBy: null | number;
  modifiedDate: null | string;
}

interface PriceType {
  id: number;
  name: string;
  createdBy: null | number;
  createdDate: null | string;
  modifiedBy: null | number;
  modifiedDate: null | string;
}

interface Tax {
  id: number;
  name: string;
  rate: number;
  type: string;
  isDefault: boolean;
  isDeleted: boolean;
  createdBy: number;
  createdDate: string;
  modifiedBy: number;
  modifiedDate: string;
}

interface Printer {
  id: number;
  name: string;
  status: null | string;
  ipAddress: string;
  createdBy: number;
  createdDate: string;
  modifiedBy: number;
  modifiedDate: string;
}

interface ModifierItem {
  id: number;
  name: string;
  price: number;
  onlinePrice: number;
  createdBy: number;
  createdDate: string;
  modifiedBy: number;
  modifiedDate: string;
  isDeleted: boolean;
}
export interface Item {
  id: number;
  name: string;
  description: string | null;
  imageUrl: string | null;
  isAvailable: boolean;
  sku: string | null;
  note: string | null;
  unit: Unit;
  priceType: PriceType;
  price: number;
  color: string | null;
  isDeleted: boolean;
  createdBy: number;
  createdDate: string;
  modifiedBy: number;
  modifiedDate: string;
  modifierSets: ModifierSets[];
  taxes: Tax[];
  printers: Printer[];
}
interface ModifierSetModifierItems {
  id: number;
  modifierItem: ModifierItem;
  createdBy: number;
  createdDate: string;
  modifiedBy: number;
  modifiedDate: string;
}

interface ModifierSets {
  createdBy: number;
  createdDate: string;
  id: number;
  isDeleted: boolean;
  maximum: number;
  modifiedBy: number;
  modifiedDate: string;
  modifierSetModifierItems: ModifierSetModifierItems[];
  name: string;
  noOfRequired: number;
  modifierItem: ModifierItem;
}

export interface Product {
  id: number;
  name: string;
  price: number;
  onlinePrice: number;
  image: string;
  description: string;
  category: string;
  tags: string[];
  link: "/:merchantname/product-detail";
  variants?: ProductVariant[];
  variantType?: "color" | "image";
  sizes?: string[];
  allOfSizes?: string[];
  status?: "New in" | "limited edition" | "Sold Out" | "50% Discount";
  imageUrl?: string;
  isAvailable?: number | undefined;
  sku?: string;
  unit?: Unit;
  priceType?: PriceType;
  color?: string;
  isDeleted?: boolean;
  createdBy?: number;
  createdDate?: string;
  modifiedBy?: number;
  modifiedDate?: string;
  modifierSets?: ModifierSets[];
  taxes?: Tax[];
  printers?: Printer[];
  quantity?: number;
  modifierChecked?: any;
  items?: Item[];
  note?: string;
  cartItemId?: number;


}
export interface ProductData {
  items: Item[];
  orderItemModifiers:ModifierSets[]
}
interface Category1 {
  items: Product[];
}
export interface Category {
  id: number;
  name: string;
  items: any;
}
export interface GetDataByCategoryIdType {
  category: CategoryData;
  items: Product[];
}

export interface CategoryData {
  id: number;
  name: string;
  isDeleted: boolean;
  createdBy: number;
  createdDate: string;
  modifiedBy: number;
  modifiedDate: string;
  items: Product[];
}
export interface menuSchedule{
  menuDays: string;
  fromTime: any;
  toTime: any;
}
//
export interface TabFiltersProps {
  categoryData?: Category[];
  setItemData?: Dispatch<SetStateAction<Product[]>>;
  itemData?: Product[];
  renderAllData?: () => void;
  getItemData?: any;
  isCategoryFilter: boolean;
}
export interface TabCategoriesFiltersProps {
  categoryData?: Category[];
  setItemData?: Dispatch<SetStateAction<Product[]>>;
  renderAllData?: () => void;
  getItemData?: any;
  searchProduct?:any
  heading?: string;
  headingFontClassName?: string;
  headingClassName?: string;
  subHeading?: string;
  data?: Product[];
}

export interface TabSortOrderFiltersProps {
  getItemData?: any;
  itemData?: Product[];
  setItemData?: Dispatch<SetStateAction<Product[]>>;
}

const DEMO_VARIANTS: ProductVariant[] = [
  {
    id: 1,
    name: "Black",
    thumbnail: productVariantImg6,
    featuredImage: productImgs[0],
  },
  {
    id: 2,
    name: "White",
    thumbnail: productVariantImg2,
    featuredImage: productImgs[1],
  },
  {
    id: 3,
    name: "Orange",
    thumbnail: productVariantImg3,
    featuredImage: productImgs[2],
  },
  {
    id: 4,
    name: "Sky Blue",
    thumbnail: productVariantImg4,
    featuredImage: productImgs[3],
  },
  {
    id: 5,
    name: "Natural",
    thumbnail: productVariantImg5,
    featuredImage: productImgs[4],
  },
];
const DEMO_VARIANT_COLORS: ProductVariant[] = [
  {
    id: 1,
    name: "Violet",
    color: "bg-violet-400",
    featuredImage: productImgs[0],
  },
  {
    id: 2,
    name: "Yellow",
    color: "bg-yellow-400",
    featuredImage: productImgs[1],
  },
  {
    id: 3,
    name: "Orange",
    color: "bg-orange-400",
    featuredImage: productImgs[2],
  },
  {
    id: 4,
    name: "Sky Blue",
    color: "bg-sky-400",
    featuredImage: productImgs[3],
  },
  {
    id: 5,
    name: "Green",
    color: "bg-green-400",
    featuredImage: productImgs[4],
  },
];

export const PRODUCTS: Product[] = [
  {
    id: 1,
    name: "Rey Nylon Backpack",
    description: "Brown cockroach wings",
    price: 74,
    onlinePrice: 100,
    image: productImgs[16],
    category: "Category 1",
    tags: ["tag1", "tag2"],
    link: "/:merchantname/product-detail",
    variants: DEMO_VARIANTS,
    variantType: "image",
    sizes: ["XS", "S", "M", "L", "XL"],
    allOfSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
    status: "New in",
  },
  {
    id: 2,
    name: 'Round Buckle 1" Belt',
    description: "Classic green",
    price: 68,
    onlinePrice: 100,
    image: productImgs[1],
    category: "Category 1",
    tags: ["tag1", "tag2"],
    link: "/:merchantname/product-detail",
    variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    status: "50% Discount",
  },
  {
    id: 3,
    name: "Waffle Knit Beanie",
    description: "New blue aqua",
    price: 132,
    onlinePrice: 100,
    image: productImgs[15],
    category: "Category 1",
    tags: ["tag1", "tag2"],
    link: "/:merchantname/product-detail",
    variants: DEMO_VARIANTS,
    variantType: "image",
    sizes: ["S", "M", "L", "XL"],
    allOfSizes: ["S", "M", "L", "XL", "2XL", "3XL"],
  },
  {
    id: 4,
    name: "Travel Pet Carrier",
    description: "Dark pink 2023",
    price: 28,
    onlinePrice: 100,
    image: productImgs[3],
    category: "Category 1",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    link: "/:merchantname/product-detail",
    status: "Sold Out",
  },
  {
    id: 5,
    name: "Leather Gloves",
    description: "Perfect mint green",
    price: 42,
    onlinePrice: 100,
    image: productImgs[4],
    category: "Category 1",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANTS,
    variantType: "image",
    sizes: ["XS", "S", "M", "L", "XL"],
    allOfSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
    link:"/:merchantname/product-detail",
  },
  {
    id: 6,
    name: "Hoodie Sweatshirt",
    description: "New design 2023",
    price: 30,
    onlinePrice: 100,
    image: productImgs[5],
    category: "Category 1",
    tags: ["tag1", "tag2"],
    variantType: "color",
    variants: DEMO_VARIANT_COLORS,
    link: "/:merchantname/product-detail",
  },
  {
    id: 7,
    name: "Wool Cashmere Jacket",
    description: "Matte black",
    price: 12,
    onlinePrice: 100,
    image: productImgs[8],
    category: "Category 1",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANTS,
    variantType: "image",
    link: "/:merchantname/product-detail",
    status: "New in",
  },
  {
    id: 8,
    name: "Ella Leather Tote",
    description: "Cream pink",
    price: 145,
    onlinePrice: 100,
    image: productImgs[7],
    category: "Category 1",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANTS,
    variantType: "image",
    sizes: ["XS", "S", "M", "L", "XL"],
    allOfSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
    link: "/:merchantname/product-detail",
    status: "limited edition",
  },
];

export const SPORT_PRODUCTS: Product[] = [
  {
    id: 1,
    name: "Mastermind Toys",
    description: "Brown cockroach wings",
    price: 74,
    onlinePrice: 100,
    image: productSport1,
    category: "Category 1",
    tags: ["tag1", "tag2"],
    link: "/:merchantname/product-detail",
    variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    sizes: ["XS", "S", "M", "L", "XL"],
    allOfSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
    status: "New in",
  },
  {
    id: 2,
    name: "Jump Rope Kids",
    description: "Classic green",
    price: 68,
    onlinePrice: 100,
    image: productSport2,
    category: "Category 1",
    tags: ["tag1", "tag2"],
    link: "/:merchantname/product-detail",
    variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    status: "50% Discount",
  },
  {
    id: 3,
    name: "Tee Ball Beanie",
    description: "New blue aqua",
    price: 132,
    onlinePrice: 100,
    image: productSport3,
    category: "Category 1",
    tags: ["tag1", "tag2"],
    link: "/:merchantname/product-detail",
    variants: DEMO_VARIANTS,
    variantType: "image",
    sizes: ["S", "M", "L", "XL"],
    allOfSizes: ["S", "M", "L", "XL", "2XL", "3XL"],
  },
  {
    id: 4,
    name: "Rubber Table Tennis",
    description: "Dark pink 2023",
    price: 28,
    onlinePrice: 100,
    image: productSport4,
    category: "Category 1",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    link: "/:merchantname/product-detail",
    status: "Sold Out",
  },
  {
    id: 5,
    name: "Classic Blue Rugby",
    description: "Perfect mint green",
    price: 42,
    onlinePrice: 100,
    image: productSport5,
    category: "Category 1",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANTS,
    variantType: "image",
    sizes: ["XS", "S", "M", "L", "XL"],
    allOfSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
    link: "/:merchantname/product-detail",
  },
  {
    id: 6,
    name: "Manhattan Toy WRT",
    description: "New design 2023",
    price: 30,
    onlinePrice: 100,
    image: productSport6,
    category: "Category 1",
    tags: ["tag1", "tag2"],
    variantType: "color",
    variants: DEMO_VARIANT_COLORS,
    link: "/:merchantname/product-detail",
  },
  {
    id: 7,
    name: "Tabletop Football ",
    description: "Matte black",
    price: 12,
    onlinePrice: 100,
    image: productSport7,
    category: "Category 1",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANTS,
    variantType: "image",
    link: "/:merchantname/product-detail",
    status: "New in",
  },
  {
    id: 8,
    name: "Pvc Catching Toy",
    description: "Cream pink",
    price: 145,
    onlinePrice: 100,
    image: productSport8,
    category: "Category 1",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    sizes: ["XS", "S", "M", "L", "XL"],
    allOfSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
    link: "/:merchantname/product-detail",
    status: "limited edition",
  },
];
