import React from "react";
import { format } from "date-fns";
import { menuSchedule } from "@/data/data";
// interface Order {
//   orderNo: number;
//   date: string;
//   totalAmount: number;
//   subTotal: number;
//   tax: number;
//   tip: number;
//   status: string;
//   orderItems: [
//     {
//       name: string;
//       quantity: number;
//       price: number;
//     }
//   ];
// }
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  menuschedule: menuSchedule[]; // Assuming Order interface is defined
}

const MenuScheduleFilter: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  menuschedule,
}) => {
  if (!isOpen) return null;
 const formatTime = (time: string | null): string => {
   if (!time) {
     return ""; // Return empty string if time is null or empty
   }

   // Create a Date object using the provided time string
   const [hours, minutes] = time.split(":").map(Number);
   const date = new Date();
   date.setHours(hours);
   date.setMinutes(minutes);
   date.setSeconds(0);

   // Convert the time to the local time zone
   const localTime = new Date(
     date.getTime() - date.getTimezoneOffset() * 60000
   );

   // Extract the local hours and minutes
   const localHours = localTime.getHours();
   const localMinutes = localTime.getMinutes();

   // Format the time in 12-hour format with AM/PM
   const period = localHours >= 12 ? "PM" : "AM";
   const hours12 = localHours % 12 || 12;
   const formattedTime = `${hours12}:${
     localMinutes < 10 ? "0" : ""
   }${localMinutes} ${period}`;

   return formattedTime;
 };
  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50"
      onClick={onClose}
    >
      <div
        className="bg-white dark:bg-gray-700 rounded-lg p-2 w-full max-w-sm h-auto overflow-y-auto max-h-full "
        onClick={(e) => e.stopPropagation()}
      >
        <h3 className="text-lg font-semibold mt-4   ml-4">Hours:</h3>
        <div className="space-y-2 ml-6 mt-4">
          {menuschedule.map((schedule, index) => (
            <div key={index} className="grid grid-cols-3 gap-4">
              <span>{schedule.menuDays}</span>
              <span>{formatTime(schedule.fromTime)}</span>
              <span>{formatTime(schedule.toTime)}</span>
            </div>
          ))}
        </div>
        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="bg-custom-green text-white font-normal py-2 px-4 rounded"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default MenuScheduleFilter;
