// import type { ThemeConfiguratorProps } from '@/components/template/ThemeConfigurator'
// import ThemeConfigurator


import SwitchDarkMode2 from "shared/SwitchDarkMode/SwitchDarkMode2"
import { IoMdClose } from "react-icons/io";


interface Props{
    setIsOpen:any
}

const SidePanelContent = ({setIsOpen}:Props) => {
    return (
        <>
        <div className="dark:bg-gray-700 flex items-center justify-between px-6 py-4">
            <h4 className="dark:text-gray-100">side panel</h4>
            <div onClick={()=>setIsOpen(false)}  className="dark:text-gray-100 p-2 rounded-full cursor-pointer">
                <IoMdClose  />
            </div>
        </div>
       <div className="flex flex-col h-full justify-between dark:bg-gray-700 px-6 pb-6">
            <div className="flex flex-col gap-y-10 mb-6">
                <div className="flex items-center justify-between">
                    <div>
                        <h6 className="dark:text-gray-100 font-bold">Dark Mode</h6>
                        <span className="dark:text-gray-100">Switch theme to dark mode</span>
                    </div>
                 <SwitchDarkMode2 />
                </div>
                {/* <div className="flex items-center justify-between">
                    <div>
                        <h6>Direction</h6>
                        <span>Select a direction</span>
                    </div>
                    <DirectionSwitcher callBackClose={callBackClose} />
                </div> */}
                {/* <div>
                    <h6 className="mb-3">Nav Mode</h6>
                    <NavModeSwitcher />
                </div> */}
                {/* <div>
                    <h6 className="mb-3">Theme</h6>
                    <ThemeSwitcher />
                </div> */}
                {/* <div>
                    <h6 className="mb-3">Layout</h6>
                    <LayoutSwitcher />
                </div> */}
            </div>
            {/* <CopyButton /> */}
        </div>
        </>
    )
}

export default SidePanelContent
