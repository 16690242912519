import { CustomLink } from "data/types";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import twFocusClass from "utils/twFocusClass";
import classNames from "classnames";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";

const DEMO_PAGINATION: CustomLink[] = [
  {
    label: "1",
    href: "#",
  },
  {
    label: "2",
    href: "#",
  },
  {
    label: "3",
    href: "#",
  },
  {
    label: "4",
    href: "#",
  },
];

export interface PaginationProps {
  className?: string;
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  loading:boolean;
}

const Pagination: FC<PaginationProps> = ({
  className = "",
  currentPage,
  totalPages,
  onPageChange,
  loading,
}) => {
  const renderItem = (pag: CustomLink, index: number) => {
    if (index === 0) {
      // RETURN ACTIVE PAGINATION
      return (
        <span
          key={index}
          className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`}
        >
          {pag.label}
        </span>
      );
    }
    // RETURN UNACTIVE PAGINATION
    return (
      <Link
        key={index}
        className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
        to={pag.href}
      >
        {pag.label}
      </Link>
    );
  };
  const paginationClass = classNames("pagination", className);
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };
  const handlePageClick = (page: number) => {
    onPageChange(page);
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
    console.log(halfMaxPagesToShow, "halfMaxPagesToShow");
    console.log(totalPages, "totalPages");

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => handlePageClick(i)}
            className={`px-4 py-2 border rounded ${
              i === currentPage ? "bg-green-200" : ""
            }`}
          >
            {i}
          </button>
        );
      }
    } else {
      let startPage = Math.max(1, currentPage - halfMaxPagesToShow);
      let endPage = Math.min(totalPages, currentPage + halfMaxPagesToShow);

      if (currentPage <= halfMaxPagesToShow) {
        endPage = maxPagesToShow;
      } else if (currentPage + halfMaxPagesToShow >= totalPages) {
        startPage = totalPages - maxPagesToShow + 1;
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => handlePageClick(i)}
            className={`px-4 py-2  ${i === currentPage ? "bg-green-200" : ""}`}
          >
            {i}
          </button>
        );
      }

      if (startPage > 1) {
        pageNumbers.unshift(
          <button
            key={1}
            onClick={() => handlePageClick(1)}
            className={`px-4 py-2  ${currentPage === 1 ? "bg-green-200" : ""}`}
          >
            1
          </button>
        );

        if (startPage > 2) {
          pageNumbers.splice(
            1,
            0,
            <span key="ellipsis-start" className="px-4 py-2">
              ...
            </span>
          );
        }
      }

      if (endPage < totalPages) {
        pageNumbers.push(
          <button
            key={totalPages}
            onClick={() => handlePageClick(totalPages)}
            className={`px-4 py-2  ${
              currentPage === totalPages ? "bg-green-200" : ""
            }`}
          >
            {totalPages}
          </button>
        );

        if (endPage < totalPages - 1) {
          pageNumbers.splice(
            pageNumbers.length - 1,
            0,
            <span key="ellipsis-end" className="px-4 py-2">
              ...
            </span>
          );
        }
      }
    }

    return pageNumbers;
  };

  return (
    // <nav
    //   className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
    // >
    //   {DEMO_PAGINATION.map(renderItem)}
    // </nav>
    <>
      <div className={`relative ${className}`}>
        <div className="flex justify-center items-center space-x-4 mt-4">
          <button
            onClick={handlePrevious}
            disabled={currentPage === 1}
            className={`px-4 py-2 ${
              currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {!loading && <HiChevronLeft />}
          </button>
          {renderPageNumbers()}
          <button
            onClick={handleNext}
            disabled={currentPage === totalPages}
            className={`px-4 py-2 ${
              currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {!loading && <HiChevronRight />}
          </button>
        </div>
      </div>
    </>
  );
};

export default Pagination;
