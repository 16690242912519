import { Product } from "data/data";
import React, { useMemo, useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";

import { useCartDataProvider } from "../../contaxt/CartProductContext";
import { useLocation } from "react-router-dom";

import axios from "axios";
import { AxiosResponse } from "axios";
import { FaCheckCircle } from "react-icons/fa";
import { Controller, FormProvider, useForm } from "react-hook-form";
import Card from "shared/Card";
import "../../App.css";
import { State } from "country-state-city";
import PaymentForm from "./PaymentForm";
import appConfig from "configs/app.config";
import BillingAddressForm from "./BillingAddress";
import OrderSummary from "./OrderSummary";
import BaseService, { getHeaders } from "services/BaseService";
import { Provider } from "react-redux";
interface ResponseData {
  // totalAmount: number;
  orderNo: number;
}
interface Payment {
  address1: string;
  address2: string;
  city: string;

  state: string;

  zip: string;
  country: string;
}
interface CardsData {
  cardNumber: string;
  expirationDate: string;
  CVC: string;
  firstname: string;
  lastname: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  payment: Payment;
}
interface DeliveryOption {
  id: string;
  provider: string;
  price: number;
  pickup_mins_from_now: number;
  delivery_mins_from_now: number;
}
const handleEdit = () => {
  console.log("Edit button clicked");
};

const handleDelete = () => {
  console.log("Delete button clicked");
};
const CheckoutPage = () => {
  const LeadTime = localStorage.getItem("onlineOrderSettingsLeadTime");
  const { Products, dispatch, searchProduct }: any = useCartDataProvider();
  const [authNet, setAuthNet] = useState("");
  const [responseData, setResponseData] = useState<ResponseData | null>(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [greenFlag, setGreenFlag] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [pickUpTime, setPickUpTime] = useState<string>("");
  const [isAsap, setIsAsap] = useState(false);
  const [selectTime, setSelectedTime] = useState(false);
  const [selectedTip, setSelectedTip] = useState("");
  const [selectedDeliveryTip, setSelectedDeliveryTip] = useState("");
  const [customTip, setCustomTip] = useState("0.00");
  const [storedOrderFee, setStoredOrderFee] = useState<number>(0);
  const [storedOrderFeeLabel, setStoredOrderFeeLabel] = useState("");
  const location = useLocation();
  const reOrderData = location.state?.item ? location.state.item : null;
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [carterror, setCartError] = useState<string>("");
  const [selectedCard, setSelectedCard] = useState<any>(null);
  const [pause, setPause] = useState<string | null>(null);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [somethingWrong, setSomethingWrong] = useState(false);
  const [isQuotesProcessing, setIsQuotesProcessing] = useState(false);
  const [historyOrders, setHistoryOrders] = useState<any>([]);
  const [subTotal, setsubTotal] = useState<number>(0);
  const { firstname, lastname, phoneNumber, email } = location.state || {};
  const MERCHANT_STORAGE_KEY = "merchantname";
  const merchantname = localStorage.getItem(MERCHANT_STORAGE_KEY);
  const [note, setNote] = useState("");
  const pauselabel = localStorage.getItem("onlineOrderSettingsPauseLabel");
  const onlineDeliveryId = localStorage.getItem("onlineDeliveryId");
  const isDelivery = localStorage.getItem("isDelivery");
  console.log(isDelivery, "checkoutisdelivery");
  const [saveCard, setSaveCard] = useState(false);
  // const [saveDelivery,setSaveDelivery]=useState(false);
  const [carddata, setCarddata] = useState<CardsData | null>(null);
  const userCustomer = localStorage.getItem("user");
  console.log(userCustomer, "userCustomer");
  const [delivery, setDelivery] = useState(false);
  const [formValue, setFormValue] = useState({});

  const [formData, setFormData] = useState({
    cardNumber: "",
    expirationDate: "",
    CVC: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    nameOnCard: "", // Assuming you have a field for name on card
  });
  type CardData = {
    cardNumber: string;
    cardType: string;

    firstName: string;
    lastName: string;
    profileId: string;
    address: string;
    city: string;
    state: string;
    zip: string;
  };
  interface Card {
    payment: {
      creditCard: {
        cardNumber: string;
        cardType: string;
      };
    };
    billTo: {
      firstName: string;
      lastName: string;
      profileId: string;
      address: string;
      city: string;
      state: string;
      zip: string;
    };
    customerPaymentProfileId: string;
  }
  interface newCard {
    billingInfo: {
      firstName: string;
      lastName: string;
      address: string;
      city: string;
      state: string;
      zipCode: string;
      country: string;
      email: string;
      phone: string;
    };
    defaulitPaymentProfile: false;
    paymentInfo: {
      cardNumber: string;
      customerProfileId: string;
      exprDate: string;
      cardCode: string;
    };
  }
  const stateOption = useMemo(() => {
    return State.getAllStates()
      .filter((code: any) => code.countryCode === "US")
      .map((state: any) => ({
        label: state.name,
        value: state.isoCode,
      }));
  }, []);
  console.log(somethingWrong, "somethingwrong");
  const formatOptionLabel = ({ label, value }: any, { context }: any) => {
    return context === "value" ? value : label;
  };
  const [user, setUser] = useState("");
  const [userdetail, setUserdetail] = useState("");
  const [lastName, setlastname] = useState("");
  const [useremail, setUseremail] = useState("");
  const [phonee, setPhone] = useState("");
  const [showCardField, setShowCardField] = useState(false);
  const [cards, setCards] = useState<CardData[]>([]);
  const [auth, setAuth] = useState<Card[]>([]);
  console.log(localStorage, "signupdatacheck");
  const customerAdded = localStorage.getItem("customerAdded");
  const userDataString = localStorage.getItem("userData");
  const SignupData = localStorage.getItem("SignupData");
  const userDataSignup = localStorage.getItem("userDataSignUp");
  const Info = localStorage.getItem("CustomerInfo");
  const [deliveryAddress, setDeliveryAddress] = useState<any>([]);
  const addedPayments = localStorage.getItem("addedPayments");
  const [onluyuth, setOnlyauth] = useState<any>([]);
  const defaultCheckedCard = auth?.slice(-1)[0];
  console.log(userDataString, localStorage, "userdataSignup1");
  const handleSelect = (card: any) => {
    setSelectedCard(card);
  };

  useEffect(() => {
    setSelectedCard(defaultCheckedCard);
  }, [defaultCheckedCard]);

  useEffect(() => {
    const existingCardsString = localStorage.getItem("newCard") || "[]";
    // setnewcardsdata (existingCardsString ? JSON.parse(existingCardsString) : []);
    const fetchDataFromApi = async () => {
      try {
        console.log(SignupData === "null", "checkkkkk11");
        console.log(
          userDataString,
          customerAdded,
          Info,
          SignupData,
          "userdataSignup1"
        );
        console.log(userDataSignup, "userdataSignup2");

        // Make the API call to fetch data
        if (userDataString && Info && !SignupData && !customerAdded) {
          const response = JSON.parse(userDataString);
          const responsetwo = JSON.parse(Info);
          console.log(response, responsetwo, "userdataa111");
          // Assuming the response is an object with a "Customer Info" property
          setlastname(responsetwo.lastName);
          console.log(responsetwo.phone, "phonenumbre");
          setPhone(responsetwo.phone);
          setUserdetail(responsetwo.firstName);
          setUseremail(responsetwo.email);
          setUser(response.customerProfileId);
          if (addedPayments) {
            setAuth(JSON.parse(addedPayments));
          } else {
            setAuth(response.paymentProfiles);
          }
        } else if (userDataString && customerAdded && SignupData === "null") {
          const response = JSON.parse(customerAdded);
          const responsetwo = JSON.parse(userDataString);
          console.log(response, responsetwo, "userdataa111");
          console.log(response.phone, "phonenumbre");
          setUserdetail(response.firstName);
          setlastname(response.lastName);
          setUseremail(response.email);
          setPhone(response.phone);
          ////setUser(response.customerId);
        } else if (SignupData && customerAdded && userDataString) {
          const response = JSON.parse(SignupData);
          const responsethree = JSON.parse(customerAdded);
          const responsetwo = JSON.parse(userDataString);
         
          console.log(response, "checkkkkk");
          setUserdetail(responsethree.firstName);
          setlastname(responsethree.lastName);
          setUseremail(responsethree.email);
          setPhone(responsethree.phone);
          setOnlyauth(response.customerPaymentProfileIdList.numericString[0]);
          setUser(response.customerProfileId);
          if (addedPayments) {
            setAuth(JSON.parse(addedPayments));
          } else {
            setAuth(
              responsetwo["AuthNet Profile Info "].paymentProfiles
            );
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call fetchDataFromApi if userData is not available in localStorage
    if (!user || !auth || !useremail || !useremail) {
      fetchDataFromApi();
    }
  }, [userDataString, customerAdded]);

  // },[auth])
  useEffect(() => {
    const storedOrderFe = localStorage.getItem("orderFee");
    const storedOrderFeeLabell = localStorage.getItem("orderFeeLabel");
    setStoredOrderFee(Number(storedOrderFe) || 0);
    setStoredOrderFeeLabel(storedOrderFeeLabell || "");
    console.log("entering");
    if (auth) {
      const cardsData: {
        cardNumber: string;
        cardType: string;
        firstName: string;
        lastName: string;
        profileId: string;
        address: string;
        city: string;
        state: string;
        zip: string;
      }[] = auth.map((card) => ({
        cardNumber: card.payment.creditCard.cardNumber.replace(/\s/g, ""),
        cardType: card.payment.creditCard.cardType,
        firstName: card.billTo.firstName,
        lastName: card.billTo.lastName,
        profileId: card.customerPaymentProfileId,
        address: card.billTo.address,
        city: card.billTo.city,
        state: card.billTo.state,
        zip: card.billTo.zip,
      }));

      setCards(cardsData);
    }
  }, [auth]);
  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };
  const handleAddCard = () => {
    // Add logic to handle adding a new card here
    setSelectedCard(null);
    setShowCardField(true);
    console.log("Add card button clicked");

    // console.log('data: ', AddressData?.profile?.paymentProfiles[0]);
  };
  useEffect(() => {
    const AddressData = JSON.parse(localStorage.getItem("userData") as any);
    console.log(AddressData,"addressdata")
    localStorage.setItem(
      "BillingAddress",
      (JSON.stringify(
        AddressData?.profile?.paymentProfiles[0]?.billTo
      ) as any) || null
    );
  }, []);

  const getCurrentTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };
  const handleSaveCard = async (data: any) => {
    console.log(data, "data@");
    if (saveCard === true) {
      setSaveCard(false);
    } else {
      setSaveCard(true);
    }
    setCarddata(data);
    setSelectedCard(null);
  };
  const hadlepickUpTime = (value: any) => {
    console.log("value", value);

    setPickUpTime(value);
  };
  const handleNoteChange = (event: any) => {
    setNote(event.target.value);
  };

  // const handlePickupTimeChange = (event: any) => {
  //   setPickupTime(event.target.value);
  //   setIsAsap(false);
  //   setSelectedTime(true);
  // };
  const optionDelivery = (value: any) => {
    console.log("optionDelivery", value);
    if (value === "delivery") {
      setDelivery(true);
    } else {
      setDelivery(false);
    }
  };

  const saveDelivery = async (data: any, value: any) => {
    // const data = (this as any).data;
    // setQuotes(true);
    setIsQuotesProcessing(true);
    console.log("savedelivery address", value, data, delivery, value === true);

    const baseurl = appConfig.apiPrefix;
    if (value === true) {
      console.log("savedelivery address", value, data, delivery);
      const id = onlineDeliveryId;
      console.log("savedelivery address", id, value, data, delivery);
      if (id && delivery === true) {
        console.log(
          "savedelivery address",
          value,
          data,
          delivery,
          data.delivery
        );
        const newData = {
          merchant_id: id,
          dropoff_street: data?.delivery?.address1,
          dropoff_city: data?.delivery?.city,
          dropoff_state: data?.delivery?.state,
          dropoff_zip: data?.delivery?.zip,
          dropoff_country: data?.delivery?.country,
          dropoff_suite: data?.delivery?.address2,
          dropoff_instructions: data?.delivery?.deliveryInstructions,
        };
        const newerData = {
          merchant_id: id,
          dropoff_street: data?.delivery?.address1,
          dropoff_city: data?.delivery?.city,
          dropoff_state: data?.delivery?.state,
          dropoff_zip: data?.delivery?.zip,
          dropoff_country: data?.delivery?.country,
          dropoff_suite: data?.delivery?.address2,
          dropoff_instructions: data?.delivery?.deliveryInstructions,
          customer_name: data?.delivery?.deliverTo,
          customer_phone: data?.delivery?.contact,
        };
        let responseMerchant: AxiosResponse<unknown, any>;
        console.log("newData", newData);
        const apiurlmerchant = "/orderOut/delivery/quotes";
        responseMerchant = await axios.post(baseurl + apiurlmerchant, newData, {
          headers: {
            MID: merchantname,
            //Timezone: getCurrentTimezone(),
            Authorization: "1iIfU6btjHW85C6HvCIG5NGfU06evEck",
          },
        });
        setDeliveryAddress(newerData);
        console.log("responseMerchant", responseMerchant, isModalOpen);
        handleResponse(responseMerchant.data);
        setIsQuotesProcessing(false);
        //  setQuotes(true);
      }
    }
  };

  // const handleAsapChange = () => {
  //   setIsAsap(true);
  //   setPickupTime("asap");
  //   setSelectedTime(false);
  // };

  const handleTipSelection = (tip: any) => {
    setSelectedTip(tip);
  };
  const handleDeliveryTipSelection = (tip: any) => {
    setSelectedDeliveryTip(tip);
  };

  const handleCustomTipChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;

    // Remove any non-numeric characters
    const numericValue = value.replace(/[^0-9]/g, "");

    // Convert the numeric value to the decimal format
    if (numericValue) {
      const formattedValue = (parseInt(numericValue, 10) / 100).toFixed(2);
      console.log("Formatted Value:", formattedValue);

      // Update the state with the formatted value
      setCustomTip(formattedValue);
      setErrorMessage(""); // Clear any previous error message
    } else {
      setCustomTip("0.00"); // Reset to default if no valid input
      setErrorMessage("Invalid tip. Please enter a positive value.");
    }
  };

  console.log("custom tip", customTip);
  const timeOptions: string[] = [];
  for (let hour = 9; hour <= 21; hour++) {
    const time = `${hour % 12 || 12} ${hour < 12 ? "AM" : "PM"}`;
    timeOptions.push(time);
  }

  const TaxDetails = (item: Product) => {
    const { taxes } = item;
    const taxDetails: Record<string, { rate: number; type: string }> = {};
    if (taxes) {
      taxes.forEach((tax) => {
        if (taxDetails[tax.name]) {
          if (taxDetails[tax.name].type === tax.type) {
            taxDetails[tax.name].rate += tax.rate;
          }
        } else {
          taxDetails[tax.name] = { rate: tax.rate, type: tax.type };
        }
      });
    }
    return taxDetails;
  };

  const taxDetails = Products.reduce(
    (
      accumulator: Record<string, { rate: number; type: string }>,
      product: Product
    ) => {
      const details = TaxDetails(product);
      Object.entries(details).forEach(([name, { rate, type }]) => {
        if (accumulator[name]) {
          if (accumulator[name].type === type) {
            accumulator[name].rate = rate;
          }
        } else {
          accumulator[name] = { rate, type };
        }
      });
      return accumulator;
    },
    {}
  );

  const reorderTaxDetails = reOrderData?.orderItems?.reduce(
    (
      accumulator: Record<string, { rate: number; type: string }>,
      product: any
    ) => {
      const details = TaxDetails(product.item);
      Object.entries(details).forEach(([name, { rate, type }]) => {
        if (accumulator[name]) {
          if (accumulator[name].type === type) {
            accumulator[name].rate = rate;
          }
        } else {
          accumulator[name] = { rate, type };
        }
      });
      return accumulator;
    },
    {}
  );

  const mergedTaxDetails = { ...taxDetails };
  console.log(
    "mergedTaxDetails",
    mergedTaxDetails,
    taxDetails,
    reorderTaxDetails
  );
  if (location.state && reorderTaxDetails) {
    Object.entries(reorderTaxDetails).forEach(([name, { rate, type }]: any) => {
      if (mergedTaxDetails[name]) {
        if (mergedTaxDetails[name].type === type) {
          mergedTaxDetails[name].rate = rate;
        }
      } else {
        mergedTaxDetails[name] = { rate, type };
      }
    });
  }

  interface TaxTotal {
    name: string;
    rate: number;
    amount: number;
  }

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
    if (responseData !== null) {
      window.location.href = `/${merchantname}`;
      localStorage.removeItem("orderCards");
    }
  };

  const TaxName = (item: Product) => {
    const { onlinePrice, quantity, modifierChecked, modifierSets, taxes } =
      item;
    let basePrice = quantity ? 1 * quantity : onlinePrice;
    let taxAmt = 0;
    const taxname: string[] = [];
    if (taxes) {
      taxes.forEach((taxes) => {
        taxname.push(taxes.name);
      });
    }
    return taxname;
  };

  interface TaxTotal {
    name: string;
    rate: number;
    amount: number;
  }

  const calculatingTotalTax = (item: any): TaxTotal[] => {
    let taxTotalses: TaxTotal[] = [];
    if (item.item) {
      let quant = item.quantity ? item.quantity : 1;
      if (location.state) {
        console.log(item, ";llllll1");
        const totalPrice: number = parseFloat(calculatingNewTotalPrice(item));
        console.log("item1212", item?.item?.taxes);
        if (item.item?.taxes) {
          item.item.taxes.forEach((tax: any) => {
            console.log("tax", tax);
            let totTaxAmt = 0;
            if (tax.type === "Percentage") {
              totTaxAmt = totalPrice * (tax.rate / 100);
              console.log("item1212", totTaxAmt);
            } else {
              totTaxAmt = quant * tax.rate;
              console.log("item1212", totTaxAmt);
            }
            taxTotalses.push({
              name: tax.name,
              rate: tax.rate,
              amount: totTaxAmt,
            });
          });
        }
        console.log("taxTotalses", taxTotalses);
      }
    } else {
      console.log(item, ";llllll2");
      const { quantity, onlinePrice, taxes } = item;
      console.log("taxes", taxes);
      if (taxes) {
        let basePrice = quantity ? 1 * quantity : onlinePrice;
        taxes.forEach((tax: any) => {
          let totTaxAmt = 0;
          if (tax.type === "Percentage") {
            totTaxAmt = calculatingTotalPrice(item) * (tax.rate / 100);
          } else {
            totTaxAmt = basePrice * tax.rate;
          }
          taxTotalses.push({
            name: tax.name,
            rate: tax.rate,
            amount: totTaxAmt,
          });
        });
      }
    }
    console.log("taxTotalses", taxTotalses);
    return taxTotalses;
  };

  const calTip = (selectedTip: any) => {
    let totalTip;
    if (selectedTip === "none") {
      totalTip = 0;
    } else {
      totalTip = parseFloat(selectedTip.replace("%,")) / 100;
    }
    const TipReturn = totalTip;
    return TipReturn;
  };
  const calDeliveryTip = (selectedDeliveryTip: any) => {
    let totalTip;
    if (selectedDeliveryTip === "none") {
      totalTip = 0;
    } else {
      totalTip = parseFloat(selectedDeliveryTip.replace("%,")) / 100;
    }
    const TipReturn = totalTip;
    return TipReturn;
  };
  const calculatingTotalPrice = (item: Product) => {
    const { onlinePrice, quantity, modifierChecked, modifierSets } = item;
    let allPrice = onlinePrice;

    if (modifierSets) {
      modifierSets.forEach((modifierSets) => {
        const matchingKey =
          modifierChecked &&
          Object.keys(modifierChecked)?.find(
            (key) => Number(key) === modifierSets.id
          );
        if (matchingKey) {
          modifierSets.modifierSetModifierItems?.forEach((modifierItem) => {
            const modifierItemId = modifierItem.id.toString();
            if (modifierChecked[matchingKey]?.[modifierItemId] !== undefined) {
              allPrice += modifierChecked[matchingKey][modifierItemId]
                ? modifierItem.modifierItem.onlinePrice
                : 0;
            }
          });
        }
      });
    }
    let basePrice = quantity ? allPrice * quantity : allPrice;
    return basePrice;
  };

  const calculatingNewTotalPrice = (item: any) => {
    const { quantity, item: itemDetails, orderItemModifiers } = item;
    console.log(itemDetails.onlinePrice, "itemdetails");
    // Calculate the base price
    let totalPrice = itemDetails.onlinePrice * quantity;

    // Calculate the modifiers price
    if (orderItemModifiers && orderItemModifiers.length > 0) {
      const modifiersPrice = orderItemModifiers.reduce(
        (acc: any, modifier: any) => {
          return acc + modifier.modifierItem.onlinePrice * quantity;
        },
        0
      );
      totalPrice += modifiersPrice;
    }

    console.log(item, totalPrice, "checklist");
    return totalPrice.toFixed(2); // Return the total price rounded to two decimal places
  };
  const OnlytaxTotals: TaxTotal[] = Products.flatMap((product: any) =>
    calculatingTotalTax(product)
  );
  const reorderTaxTotals: TaxTotal[] =
    reOrderData?.orderItems?.flatMap((product: any) =>
      calculatingTotalTax(product)
    ) || [];

  let taxTotals: TaxTotal[] = [];
  console.log("taxTotals", taxTotals, OnlytaxTotals, reorderTaxTotals);
  // Combine arrays only if they are not empty
  if (OnlytaxTotals.length > 0 || reorderTaxTotals.length > 0) {
    if (OnlytaxTotals.length > 0) {
      console.log("taxTotals", reorderTaxTotals);
      taxTotals = [...OnlytaxTotals];
    } else if (reorderTaxTotals.length > 0) {
      console.log("taxTotals", reorderTaxTotals);
      taxTotals = [...reorderTaxTotals];
    }
  }

  console.log("taxTotals", taxTotals, reorderTaxTotals);
  const taxDisplayTotal = Object.values(
    taxTotals.reduce((accumulator: Record<string, number>, taxTotal) => {
      const { name, amount } = taxTotal;
      accumulator[name] = (accumulator[name] || 0) + amount;
      return accumulator;
    }, {})
  ).map((totalAmount: number) => totalAmount.toFixed(2));
  const seenTypes = new Set<string>();

  const taxDisplayList: string[] = [];

  taxTotals.forEach(({ name, rate }) => {
    const { type } = mergedTaxDetails[name];
    if (!seenTypes.has(type)) {
      seenTypes.add(type);
      if (type === "Percentage") {
        taxDisplayList.push(`${name}: ${rate}%`);
      } else if (type === "Fixed") {
        taxDisplayList.push(`${name}: $${rate}`);
      } else {
        taxDisplayList.push(`${name}: ${rate}`); // Fallback in case of an unexpected type
      }
    }
  });

  console.log("taxDisplayList1212", taxDisplayList, taxDisplayTotal);
  const selectedModifierItems = (item: Product) => {
    let selectedModifierItemIds: number[] = [];
    const { onlinePrice, quantity, modifierChecked, modifierSets, note } = item;
    if (modifierSets) {
      modifierSets.forEach((modifierSets) => {
        const matchingKey =
          modifierChecked &&
          Object.keys(modifierChecked)?.find(
            (key) => Number(key) === modifierSets.id
          );
        if (matchingKey) {
          modifierSets.modifierSetModifierItems?.forEach((modifierItem) => {
            const modifierItemId = modifierItem.id.toString();
            if (modifierChecked[matchingKey]?.[modifierItemId] !== undefined) {
              selectedModifierItemIds.push(modifierItem.modifierItem.id);
            }
          });
        }
      });
    }
    return selectedModifierItemIds;
  };

  const validateCardNumber = (values: any) => {
    const cardNumber = values.replace(/\s/g, ""); // Remove all spaces

    if (!cardNumber) {
      return "Card number is required";
    } else if (cardNumber.length < 13) {
      return "Card number must be at least 13 digits";
    } else if (cardNumber.length > 16) {
      return "Card number must be no more than 16 digits";
    } else {
      return true;
    }
  };

  const validateData = (value: any) => {
    if (!/^\d{2}\/\d{2}$/.test(value)) {
      return "Expiration date must be in MM/YY format";
    }
    const [month, year] = value.split("/").map(Number);
    if (month < 1 || month > 12) {
      return "Expiration month must be between 01 and 12";
    }

    const currentYear = new Date().getFullYear() % 100;
    const currentMonth = new Date().getMonth() + 1;

    if (year < currentYear || (year === currentYear && month < currentMonth)) {
      return "Invalid Expiration date";
    } else {
      return true;
    }
  };

  const methods = useForm({
    defaultValues: {
      delivery: {
        deliverTo: "",
        contact: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        country: "USA",
        zip: "",
        deliveryInstructions: "",
      },

      cardNumber: "",
      firstname: "",
      lastname: "",
      expirationDate: "",
      CVC: "",
      payment: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        country: "USA ",
        zip: "",
      },
    },
  });
  const {
    control,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = methods;

  const cleanPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber) {
      return phoneNumber.replace(/\D/g, "");
    } else {
      return null;
    }
  };
  // useEffect(() => {
  //   const fetchCustomerProfile = async () => {
  //     try {
  //       if (!userCustomer || userCustomer === null) return;
  //       const apiurlone = `/onlineOrder/customer`;
  //       let responseAdded: AxiosResponse<any, any>;
  //       const baseurl = appConfig.apiPrefix;
  //       responseAdded = await axios.get(baseurl + apiurlone, {
  //         headers: {
  //           MID: merchantname,
  //           Timezone: getCurrentTimezone(),
  //         },
  //         params: {
  //           customerProfileId: userCustomer, // Query parameter
  //         },
  //       });

  //       console.log(responseAdded, "responseAdded");

  //       if (responseAdded?.data?.profile) {
  //         // setUserCustomer(responseAdded.data.profile?.customerProfileId);
  //         localStorage.setItem(
  //           "user",
  //           responseAdded.data.profile?.customerProfileId
  //         );
  //         setAuth(responseAdded.data.profile?.paymentProfiles);
  //         setUser(responseAdded.data.profile?.customerProfileId);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching customer profile:", error);
  //     }
  //   };
  //   fetchCustomerProfile();
  // }, [userCustomer]);
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [quoteId, setQuoteId] = useState("");
  const [quoteProvider, setQuoteProvider] = useState("");
  const [deliveryFee, setDeliveryFee] = useState("");
  const [estimatedTime, setEstimatedTime] = useState(0);
  const [pickUp, setPickUp] = useState(0);

  const handleResponse = (response: any) => {
    console.log("handleResponse", response);
    const parsedData = JSON.parse(response.Body);
    setDeliveryOptions(parsedData);
    setIsPopupVisible(!isPopupVisible);
    setIsModalOpen(true); // Open the modal
  };
  useEffect(() => {
    const fetchCustomerProfile = async () => {
      try {
        if (!userCustomer || userCustomer === null) return;
        const apiurlone = `/onlineOrder/customer`;
        let responseAdded: AxiosResponse<any, any>;
        const baseurl = appConfig.apiPrefix;
        responseAdded = await axios.get(baseurl + apiurlone, {
          headers: {
            MID: merchantname,
            timeZone: getCurrentTimezone(),
          },
          params: {
            customerProfileId: userCustomer, // Query parameter
          },
        });

        console.log(responseAdded, "responseAdded");

        if (responseAdded?.data) {
          // setUserCustomer(responseAdded.data.profile?.customerProfileId);
          localStorage.setItem(
            "user",
            responseAdded.data?.customerProfileId
          );
          setAuth(responseAdded.data?.paymentProfiles);
          setUser(responseAdded.data?.customerProfileId);
          const AddressData = responseAdded?.data;
          localStorage.setItem(
            "BillingAddress",
            (JSON.stringify(
              AddressData?.profile?.paymentProfiles[0]?.billTo
            ) as any) || null
          );
        }
      } catch (error) {
        console.error("Error fetching customer profile:", error);
      }
    };
    fetchCustomerProfile();
  }, [userCustomer]);

  const formatPickupTime = (pickupTime: any) => {
    const [datePart, timePart] = pickupTime.split(" ");
    const [day, month, year] = datePart.split("-");
    const [hours, minutes] = timePart.split(":");

    const date = new Date(year, month - 1, day, hours, minutes);

    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    } as const;
    console.log(datePart, "datepart");
    return datePart + " " + date.toLocaleString("en-US", options);
  };
  const submitOrder = async (data: any) => {
    console.log("submitOrder", reOrderData, data);
    setGreenFlag(false);
    setIsPaymentProcessing(true);
    if (Products.length === 0 && reOrderData.orderItems.length === 0) {
      // Display an error message and return early to prevent API call
      setCartError(
        "Your cart is empty. Please add items to the cart before submitting the order."
      );
      return;
    } else {
      setCartError("");
    }
    const pauseOrder = localStorage.getItem("pause");
    setPause(pauseOrder);
    console.log(pauseOrder, data, Products, "pauseOrder");
    const historyOrderSubmit = historyOrders?.map((item: any) => ({
      itemId: item.item.id,
      price: item.price,
      quantity: item.quantity,
      note: item.note,
      modifierSetItemsList: item.orderItemModifiers.map(
        (modifier: any) => modifier.modifierItem.id
      ),
    }));
    console.log("eachItem", Products);
    const ProductOrder = Products.map((eachItem: any) => ({
      itemId: eachItem.id,
      modifierSetItemsList: selectedModifierItems(eachItem),
      price: eachItem.onlinePrice,
      quantity: eachItem.quantity,
      note: eachItem.note,
    }));
    const allProductOrder = [
      ...ProductOrder,
      ...(historyOrders?.length > 0 ? historyOrderSubmit : []),
    ];

    try {
      setSomethingWrong(false);
      let orderData;
      console.log(
        auth.length,
        SignupData,
        selectedCard,
        userDataString,
        user,
        carddata,
        saveCard,
        data,
        "selectedCard"
      );
      if (auth.length && SignupData && selectedCard) {
        console.log(
          auth,
          user,
          selectedCard.customerPaymentProfileId,
          "selectedCard.customerPaymentProfileId"
        );

        orderData = {
          authNet: {
            customerProfileId: user,
            paymentProfileId: onluyuth,
            // amount: totAmt.toFixed(2),
          },
          basilOrder: {
            customerInfo: {
              firstName: selectedCard.billTo.firstName,
              lastName: selectedCard.billTo.lastName,
              email: useremail,
              phone: cleanPhoneNumber(phonee),
              address: selectedCard.billTo.address,
              city: selectedCard.billTo.city,
              state: selectedCard.billTo.state,
              country: "USA",
              addressType: "Billing",
              zipCode: selectedCard.billTo.zip,
            },
            orderItemList: allProductOrder,
            pickUpTime: delivery ? "" : pickUpTime,
            status: "pending",
            subTotal: subTotal.toFixed(2),
            tip: selectedTip ? finalTip(selectedTip) : 0.0,
            type: "online",
            discount: 0,
            note: note,
          },
        };
      } else if (auth.length && userDataString && selectedCard) {
        console.log(
          auth,
          user,
          selectedCard,
          "selectedCard.customerPaymentProfileId"
        );
        orderData = {
          authNet: {
            customerProfileId: user,
            paymentProfileId: selectedCard.customerPaymentProfileId,
            // amount: totAmt.toFixed(2),
          },
          basilOrder: {
            customerInfo: {
              firstName: selectedCard.billTo.firstName,
              lastName: selectedCard.billTo.lastName,
              email: useremail,
              phone: cleanPhoneNumber(phonee),
              address: selectedCard.billTo.address,
              city: selectedCard.billTo.city,
              state: selectedCard.billTo.state,
              country: "USA",
              addressType: "Billing",
              zipCode: selectedCard.billTo.zip,
            },
            orderItemList: allProductOrder,
            pickUpTime: delivery ? "" : pickUpTime,
            status: "pending",
            subTotal: subTotal.toFixed(2),
            tip: selectedTip ? finalTip(selectedTip) : 0.0,
            type: "online",
            discount: 0,
            note: note,
          },
        };
      } else if (!selectedCard && saveCard && carddata && userDataString) {
        console.log("selectedCard entering", carddata, data);
        orderData = {
          authNet: {
            cardNumber: carddata.cardNumber.replace(/\s/g, ""),
            exprDate: carddata.expirationDate,
            cardCode: carddata.CVC,
            customerProfileId: user,
            defaultPaymentProfile: false,
          },
          basilOrder: {
            customerInfo: {
              firstName: carddata.firstname,
              lastName: carddata.lastname,
              email: useremail,
              phone: cleanPhoneNumber(phonee),
              address:
                carddata?.payment?.address1 + " " + carddata?.payment.address2,
              city: carddata.payment.city,
              state: carddata.payment.state,
              country: carddata.payment.country,
              addressType: "Billing",
              zipCode: carddata.payment.zip,
            },
            orderItemList: allProductOrder,
            pickUpTime: delivery ? "" : pickUpTime,
            status: "pending",
            subTotal: subTotal.toFixed(2),
            tip: selectedTip ? finalTip(selectedTip) : 0.0,
            type: "online",
            discount: 0,
            note: note,
          },
        };
      } else if (!selectedCard && saveCard === false && userDataString) {
        console.log("selectedCard entering2");
        orderData = {
          authNet: {
            cardNumber: data.cardNumber.replace(/\s/g, ""),
            exprDate: data.expirationDate,
            cardCode: data.CVC,
            customerProfileId: user,
            defaultPaymentProfile: false,
          },
          basilOrder: {
            customerInfo: {
              firstName: data.firstname,
              lastName: data.lastname,
              email: useremail,
              phone: cleanPhoneNumber(phonee),
              address: data.payment.address1 + " " + data.payment.address2,
              city: data.payment.city,
              state: data.payment.state,
              country: data.payment.country,
              addressType: "Billing",
              zipCode: data.payment.zip,
            },
            orderItemList: allProductOrder,
            pickUpTime: delivery ? "" : pickUpTime,
            status: "pending",
            subTotal: subTotal.toFixed(2),
            tip: selectedTip ? finalTip(selectedTip) : 0.0,
            type: "online",
            discount: 0,
            note: note,
          },
        };
      } else if (!auth.length && userDataString) {
        orderData = {
          authNet: {
            cardNumber: data.cardNumber.replace(/\s/g, ""),
            exprDate: data.expirationDate,
            cardCode: data.CVC,
            customerProfileId: "",
            defaultPaymentProfile: false,
          },
          basilOrder: {
            customerInfo: {
              firstName: data.firstname,
              lastName: data.lastname,
              email: useremail,
              phone: cleanPhoneNumber(phonee),
              address: data.payment.address1,
              city: data.payment.city,
              state: data.payment.state,
              country: data.payment.country,
              addressType: "Billing",
              zipCode: data.payment.zip,
            },
            orderItemList: allProductOrder,
            pickUpTime: delivery ? "" : pickUpTime,
            status: "pending",
            subTotal: subTotal.toFixed(2),
            tip: selectedTip ? finalTip(selectedTip) : 0.0,
            type: "online",
            discount: 0,
            note: note,
          },
        };
      } else if (!auth.length && SignupData) {
        orderData = {
          authNet: {
            cardNumber: data.cardNumber.replace(/\s/g, ""),
            exprDate: data.expirationDate,
            cardCode: data.CVC,
            customerProfileId: "",
            defaultPaymentProfile: false,
          },
          basilOrder: {
            customerInfo: {
              firstName: data.firstname,
              lastName: data.lastname,
              email: useremail,
              phone: cleanPhoneNumber(phonee),
              address: data.payment.address1,
              city: data.payment.city,
              state: data.payment.state,
              country: data.payment.country,
              addressType: "Billing",
              zipCode: data.payment.zip,
            },
            orderItemList: allProductOrder,
            pickUpTime: delivery ? "" : pickUpTime,
            status: "pending",
            subTotal: subTotal.toFixed(2),
            tip: selectedTip ? finalTip(selectedTip) : 0.0,
            type: "online",
            discount: 0,
            note: note,
          },
        };
      } else {
        console.log("selectedCard entering3", data);
        orderData = {
          authNet: {
            cardNumber: data.cardNumber.replace(/\s/g, ""),
            exprDate: data.expirationDate,
            cardCode: data.CVC,
            customerProfileId: "",
            defaultPaymentProfile: false,
          },
          basilOrder: {
            customerInfo: {
              firstName: data.firstname,
              lastName: data.lastname,
              email: email,
              phone: cleanPhoneNumber(phoneNumber),
              address: data.payment.address1,
              city: data.payment.city,
              state: data.payment.state,
              country: data.payment.country,
              addressType: "Billing",
              zipCode: data.payment.zip,
            },
            orderItemList: allProductOrder,
            pickUpTime: delivery ? "" : pickUpTime,
            status: "pending",
            subTotal: subTotal.toFixed(2),
            tip: selectedTip ? finalTip(selectedTip) : 0.0,
            type: "online",
            discount: 0,
            note: note,
          },
        };
      }
      let orderDataUpdated;
      if (delivery === true) {
        orderDataUpdated = {
          orderOut: {
            driver_tip: selectedDeliveryTip
              ? finalDeliveryTip(selectedDeliveryTip) * 100
              : 0,
            deliveryFee: Number(deliveryFee),
          },
          authNet: {
            ...orderData.authNet,
          },
          basilOrder: {
            ...orderData.basilOrder,
          },
        };
      } else if (delivery === false) {
        orderDataUpdated = {
          authNet: {
            ...orderData.authNet,
          },
          basilOrder: {
            ...orderData.basilOrder,
          },
        };
      }

      const apiUrl = `/onlineOrder/payment/customer`;
      const apiurlone = `/onlineOrder/payment`;
      const header = getHeaders();
      const baseurl = appConfig.apiPrefix;

      let response: AxiosResponse<unknown, any>;
      if (!userDataString && !SignupData) {
        console.log("ifelse", saveCard, selectedCard);
        response = await axios.post(baseurl + apiurlone, orderDataUpdated, {
          headers: {
            cardSave: saveCard,
            isDelivery: delivery ? "True" : "False",
            MID: merchantname,
            timeZone: getCurrentTimezone(),
          },
        });
      } else if (!selectedCard && (userDataString || SignupData)) {
        console.log("if");
        response = await axios.post(baseurl + apiurlone, orderDataUpdated, {
          headers: {
            cardSave: saveCard,
            isDelivery: delivery ? "True" : "False",
            MID: merchantname,
            Timezone: getCurrentTimezone(),
          },
        });
      } else {
        console.log("else");
        response = await axios.post(baseurl + apiUrl, orderDataUpdated, {
          headers: {
            // cardSave: saveCard,
            isDelivery: delivery ? "True" : "False",
            MID: merchantname,
            Timezone: getCurrentTimezone(),
          },
        });
      }

      const message = response.data as Record<string, unknown>;
      console.log("message", message["Error message"]);

      // const Auth: string = !delivery ? (message["Auth Net: "] as string) : "";
      const transId = message?.TransId as string;

      if (message["Error message"]) {
        const ErrorAuth: string = message["Error message"] as string;
        if (ErrorAuth) {
          const errorMsg = ErrorAuth;
          setAuthNet(errorMsg);
        }
      } else if (transId == "0") {
        const ErrorAuth =
          "Issue authorizing payment. Please check the card details and try again";
        if (ErrorAuth) {
          const errorMsg = ErrorAuth;
          setAuthNet(errorMsg);
        }
      }
      if (transId && quoteId && transId != "0") {
        console.log(data, localStorage, "aaaaaa");

        setGreenFlag(true);
        // const newData=
        let responseCustomer: AxiosResponse<unknown, any>;

        const OrderCustomer = {
          orderOut: {
            merchant_id: Number(onlineDeliveryId),
            quote_id: quoteId,
            dropoff_street: data?.delivery?.address1,
            dropoff_city: data?.delivery?.city,
            dropoff_state: data?.delivery?.state,
            dropoff_zip: data?.delivery?.zip,
            dropoff_country: data?.delivery?.country,
            dropoff_suite: data?.delivery?.address2,
            dropoff_instructions: data?.delivery?.deliveryInstructions,
            customer_name: data?.delivery?.deliverTo,
            customer_phone: data?.delivery?.contact,
            subtotal: Number((subTotal * 100).toFixed(0)),
            deliveryFee: Number(deliveryFee),
            driver_tip: selectedDeliveryTip
              ? finalDeliveryTip(selectedDeliveryTip) * 100
              : 0,
          },
          authNet: {
            ...orderData.authNet, // Spread operator to include all fields from orderData.authNet
          },
          basilOrder: {
            transId: transId,
            providerPickUpTime: pickUp.toString(),
            providerDeliveryTime: estimatedTime.toString(),
            provider: quoteProvider,

            ...orderData.basilOrder,
          },
        };
        console.log("OrderMerchant", OrderCustomer);

        const apiurlmerchant = "/orderOut/delivery/orders";
        console.log(localStorage.getItem("user"), "userstringtest");
        responseCustomer = await axios.post(
          baseurl + apiurlmerchant,
          OrderCustomer,
          {
            headers: {
              MID: merchantname,
              cardSave: saveCard,
              isDelivery: delivery ? "True" : "False",
              customerId: selectedCard?.customerPaymentProfileId ? user : "",
              Timezone: getCurrentTimezone(),
              Authorization: "1iIfU6btjHW85C6HvCIG5NGfU06evEck",
            },
          }
        );

        console.log("responseMerchant", responseCustomer);
        const bodyObject = responseCustomer.data as {
          data: { [key: string]: string };
        };

        console.log(
          "RequiredData",
          (bodyObject as any)["Auth Net: "],
          bodyObject,
          message,
          user,
          userCustomer
        );

        //setGreenFlag(true);

        if (
          (bodyObject as any)["Auth Net: "] ===
          "This transaction has been approved."
        ) {
          setGreenFlag(true);
          setIsPopupVisible(!isPopupVisible);
          setAuthNet((bodyObject as any)["Auth Net: "]);
          const RequiredData = (bodyObject as any)["Basil Order"] as any;
          console.log("RequiredData", message, user, userCustomer);
          if (
            (!user || user === null) &&
            (bodyObject as any)["NewAuthNetProfile"]
          ) {
            const newCustomerId = (
              (bodyObject as any)["NewAuthNetProfile"] as {
                customerProfileId: string;
              }
            ).customerProfileId;
            localStorage.setItem("user", newCustomerId);
          }

          const requiredData: ResponseData = {
            // totalAmount: RequiredData.totalAmount,
            orderNo: RequiredData.orderNo,
          };

          setResponseData(requiredData);
        }
      }
      console.log(message, "message123456");
      const Auth: string = !delivery
        ? (message?.Description as string)
        : authNet;
      console.log(
        message?.Description === undefined,
        !message?.Description,message["Error message"],
        "message123456"
      );
      if (Auth === "This transaction has been approved." && !delivery) {
        console.log(data, localStorage, "aaaaaa");

        setGreenFlag(true);
        setIsPopupVisible(!isPopupVisible);

        const RequiredData = message["Basil Order"] as any;
        console.log("RequiredData", user, userCustomer);

        console.log("RequiredData", message, user, userCustomer);
        if ((!user || user === null) && message["NewAuthNetProfile"]) {
          console.log("enteringin");
          const newCustomerId = (
            message["NewAuthNetProfile"] as { customerProfileId: string }
          ).customerProfileId;
          localStorage.setItem("user", newCustomerId);
        }

        const requiredData: ResponseData = {
          // totalAmount: RequiredData.totalAmount,
          orderNo: RequiredData.orderNo,
        };
        setResponseData(requiredData);
        //setGreenFlag(true);
        setAuthNet(Auth);
      } else if (message?.Description === undefined || Auth===undefined) {
        const ErrorAuth: string = message["Error message"] as string;
        if (ErrorAuth) {
          console.log(ErrorAuth, "ErrorAuth");
          const errorMsg = ErrorAuth;
          setAuthNet(errorMsg);
        }
      }

      setIsPaymentProcessing(false);
      setIsPopupVisible(!isPopupVisible);
      // reset();
    } catch (error) {
      console.error("Error submitting order:", error);
      setSomethingWrong(true);
      setIsPaymentProcessing(false);
    }
  };
  console.log(pause, "pause");
  console.log(
    isModalOpen,
    greenFlag,
    isPopupVisible,
    somethingWrong,
    "Somethingwenwrong"
  );
  const NoteContent = () => {
    return (
      <>
        <div className="w-full rounded-2xl space-y-2.5">
          <h3 className="flex items-center justify-between w-full px-4 py-2 font-medium text-left bg-slate-100/80 hover:bg-slate-200/60 dark:bg-slate-800 dark:hover:bg-slate-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75 ">
            Add Note
          </h3>
          <textarea
            placeholder=""
            className="w-full h-20 px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-slate-500 focus:ring-opacity-75"
            value={note}
            onChange={handleNoteChange}
          />
        </div>
      </>
    );
  };

  const allProductPrice = Products.reduce(
    (accumulator: number, currentValue: Product) =>
      accumulator + calculatingTotalPrice(currentValue),
    0
  );

  const amtTax = Products.reduce(
    (accumulator: number, currentValue: Product) => {
      const taxTotals: TaxTotal[] = calculatingTotalTax(currentValue);
      console.log("taxTotals11", taxTotals);
      const totalTaxForProduct = taxTotals.reduce(
        (totalTax: number, taxTotal: TaxTotal) => totalTax + taxTotal.amount,
        0
      );
      return accumulator + totalTaxForProduct;
    },
    0
  );

  const finalTip = (selectedTip: string) => {
    let calculateTip = 0.0;
    if (selectedTip !== "custom") {
      calculateTip =
        (parseFloat(location.state ? subTotal : allProductPrice) +
          parseFloat(amtTax)) *
        calTip(selectedTip.toString());
    } else if (selectedTip === "custom") {
      if (customTip != null && customTip !== "") {
        calculateTip = parseFloat(customTip);
      } else {
        calculateTip = 0;
      }
    } else {
      calculateTip = 0;
    }

    return parseFloat(calculateTip.toFixed(2));
  };
  const finalDeliveryTip = (selectedDeliveryTip: string) => {
    let calculateTip = 0.0;
    if (selectedDeliveryTip !== "custom") {
      calculateTip =
        (parseFloat(location.state ? subTotal : allProductPrice) +
          parseFloat(amtTax)) *
        calDeliveryTip(selectedDeliveryTip.toString());
    } else if (selectedDeliveryTip === "custom") {
      if (customTip != null && customTip !== "") {
        calculateTip = parseFloat(customTip);
      } else {
        calculateTip = 0;
      }
    } else {
      calculateTip = 0;
    }

    return parseFloat(calculateTip.toFixed(2));
  };

  const taxnames = Products.reduce(
    (accumulator: string[], currentValue: Product) => {
      return [...accumulator, TaxName(currentValue)];
    },
    []
  );

  const taxnamelist: Set<string> = new Set();
  taxnames.forEach((tax: string[]) => {
    tax.forEach((taxName: string) => {
      taxnamelist.add(taxName);
    });
  });

  let totAmt = 0.0;
  if (selectedTip) {
    const tipAmount = finalTip(selectedTip).toFixed(2);
    totAmt =
      parseFloat(allProductPrice) +
      parseFloat(amtTax) +
      storedOrderFee +
      parseFloat(tipAmount);
  } else if (selectedDeliveryTip) {
    const tipAmount = finalTip(selectedDeliveryTip).toFixed(2);
    totAmt =
      parseFloat(allProductPrice) +
      parseFloat(amtTax) +
      storedOrderFee +
      parseFloat(tipAmount);
  } else {
    totAmt = parseFloat(allProductPrice) + parseFloat(amtTax) + storedOrderFee;
  }
  console.log(pause, "pause1");

  const [showTime, setShowTime] = useState("");
  useEffect(() => {
    let getTime = parseInt(
      localStorage.getItem("onlineOrderSettingsLeadTime") || "0"
    );
    if (estimatedTime && delivery) {
      getTime = estimatedTime + getTime;
    } else {
      getTime = parseInt(
        localStorage.getItem("onlineOrderSettingsLeadTime") || "0"
      );
    }
    let currentTime = new Date();

    currentTime.setMinutes(currentTime.getMinutes() + getTime);

    let hours: any = currentTime.getHours();
    let minutes: any = currentTime.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    let formattedTime = hours + ":" + minutes + " " + ampm;
    console.log(formattedTime, "formattedTime");
    setShowTime(formattedTime);
  }, [
    localStorage.getItem("onlineOrderSettingsLeadTime"),
    estimatedTime,
    delivery,
  ]);
  console.log(Products, "Products");
  return (
    <div className="nc-CheckoutPage">
      <Helmet>
        <title>Basil POS Online Ordering</title>
      </Helmet>
      <main className="container py-16 lg:pb-28 lg:pt-20 ">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitOrder)}>
            <div className="mb-16">
              <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
                Checkout
              </h2>
              <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
                <Link to={`/${merchantname}`} className="">
                  Menu
                </Link>
                <span className="text-xs mx-1 sm:mx-1.5">/</span>
                <Link to={`/${merchantname}/cart`} className="">
                  Cart
                </Link>

                {!userDataString && !SignupData && (
                  <span className="text-xs mx-1 sm:mx-1.5">/</span>
                )}
                {!userDataString && !SignupData && (
                  <Link to={`/${merchantname}/userdetails`} className="">
                    User Details
                  </Link>
                )}
                <span className="text-xs mx-1 sm:mx-1.5">/</span>
                <span className="underline">Checkout</span>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row">
              <div className="flex-1">
                <div className="space-y-8">
                  <div id="PaymentMethod" className="scroll-mt-24">
                    <div className="flex items-start space-x-4 sm:space-x-6">
                      <div className="flex-1">
                        <label
                          htmlFor="Credit-Card"
                          className="flex items-center space-x-4 sm:space-x-6"
                        >
                          <div className={`p-2.5 rounded-xl border-2 `}>
                            <svg
                              className="w-6 h-6 sm:w-7 sm:h-7"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M2 12.6101H19"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M19 10.28V17.43C18.97 20.28 18.19 21 15.22 21H5.78003C2.76003 21 2 20.2501 2 17.2701V10.28C2 7.58005 2.63 6.71005 5 6.57005C5.24 6.56005 5.50003 6.55005 5.78003 6.55005H15.22C18.24 6.55005 19 7.30005 19 10.28Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M22 6.73V13.72C22 16.42 21.37 17.29 19 17.43V10.28C19 7.3 18.24 6.55 15.22 6.55H5.78003C5.50003 6.55 5.24 6.56 5 6.57C5.03 3.72 5.81003 3 8.78003 3H18.22C21.24 3 22 3.75 22 6.73Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M5.25 17.8101H6.96997"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M9.10986 17.8101H12.5499"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <p className="font-medium">Debit / Credit Card</p>
                        </label>

                        <div className={`mt-6 mb-4 space-y-3 sm:space-y-5`}>
                          {auth?.length && !showCardField ? (
                            <div className="p-4 mb-3">
                              {auth.map(
                                (card, index) => (
                                  console.log(card, "card"),
                                  (
                                    <Card
                                      key={index}
                                      card={card}
                                      onSelect={handleSelect}
                                      selected={
                                        selectedCard &&
                                        selectedCard.payment.creditCard.cardNumber.replace(
                                          /\s/g,
                                          ""
                                        ) ===
                                          card.payment.creditCard.cardNumber.replace(
                                            /\s/g,
                                            ""
                                          )
                                      }
                                      defaulChecked={
                                        defaultCheckedCard.payment.creditCard
                                          .cardNumber ===
                                        card.payment.creditCard.cardNumber.replace(
                                          /\s/g,
                                          ""
                                        )
                                      }
                                      cardType={
                                        card.payment.creditCard.cardType
                                      }
                                      cardHolderName={`${card.billTo.firstName} ${card.billTo.lastName}`}
                                      cardNumber={card.payment.creditCard.cardNumber.replace(
                                        /\s/g,
                                        ""
                                      )}
                                      onEdit={() => handleEdit}
                                      onDelete={() => handleDelete}
                                    />
                                  )
                                )
                              )}
                              {!showCardField && (
                                <button
                                  onClick={handleAddCard}
                                  className="mt-4 bg-custom-green text-white font-normal py-2 px-4 rounded"
                                >
                                  Add Card
                                </button>
                              )}
                            </div>
                          ) : (
                            <></>
                            // <PaymentForm
                            //   control={control} // Pass your form control
                            //   errors={errors} // Pass your form errors
                            //   handleAddCard={handleAddCard} // Pass your handleAddCard function
                            //   validateCardNumber={validateCardNumber} // Pass your validateCardNumber function
                            //   validateData={validateData} // Pass your validateData function
                            // />
                          )}
                          {showCardField && (
                            <form>
                              <PaymentForm
                                control={control} // Pass your form control
                                errors={errors} // Pass your form errors
                                handleAddCard={handleAddCard} // Pass your handleAddCard function
                                validateCardNumber={validateCardNumber} // Pass your validateCardNumber function
                                validateData={validateData}
                              />
                              <BillingAddressForm
                                control={control}
                                errors={errors}
                                setValue={setValue}
                                clearErrors={clearErrors}
                                namespace="payment"
                              />

                              <div className="mt-6 flex items-center">
                                <input
                                  type="checkbox"
                                  id="saveForFuturePayments"
                                  className="h-4 w-4 text-blue-500 border-gray-300 rounded focus:ring-blue-400"
                                  onChange={() => {
                                    handleSubmit(handleSaveCard)();
                                  }}
                                />
                                <label
                                  htmlFor="saveForFuturePayments"
                                  className="ml-2 text-sm font-medium text-gray-700"
                                >
                                  Save for future payments
                                </label>
                              </div>
                            </form>
                          )}
                          {!auth?.length ? (
                            <>
                              <PaymentForm
                                control={control}
                                errors={errors}
                                handleAddCard={handleAddCard} // Pass your handleAddCard function
                                validateCardNumber={validateCardNumber} // Pass your validateCardNumber function
                                validateData={validateData}
                              />
                              <BillingAddressForm
                                control={control}
                                errors={errors}
                                setValue={setValue}
                                clearErrors={clearErrors}
                                namespace="payment"
                              />
                              {userDataString && (
                                <div className="mt-6 flex items-center">
                                  <input
                                    type="checkbox"
                                    id="saveForFuturePayments"
                                    className="h-4 w-4 text-blue-500 border-gray-300 rounded focus:ring-blue-400"
                                    onChange={() => {
                                      handleSubmit(handleSaveCard)();
                                    }}
                                  />
                                  <label
                                    htmlFor="saveForFuturePayments"
                                    className="ml-2 text-sm font-medium text-gray-700"
                                  >
                                    Save for future payments
                                  </label>
                                </div>
                              )}
                            </>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex-shrink-0 border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:lg:mx-14 2xl:mx-16 ">
                {/* {Products.length === 0 && (
                <p className="text-red-500 mt-2">{carterror}</p>
              )} */}
              </div>

              <OrderSummary
                products={Products}
                reOrderData={reOrderData}
                setHistoryOrders={setHistoryOrders}
                setsubTotal={setsubTotal}
                searchProduct={searchProduct}
                calculatingTotalPrice={calculatingTotalPrice} // Example function for calculating total price
                merchantname={merchantname ?? `${merchantname}`}
                toggleAccordion={toggleAccordion}
                isAccordionOpen={isAccordionOpen}
                selectTime={selectTime}
                pickUpTime={hadlepickUpTime}
                // handlePickupTimeChange={handlePickupTimeChange}
                // handleAsapChange={handleAsapChange}
                timeOptions={timeOptions as string[]}
                isAsap={isAsap}
                handleTipSelection={handleTipSelection}
                selectedTip={selectedTip}
                handleDeliveryTipSelection={handleDeliveryTipSelection}
                selectedDeliveryTip={selectedDeliveryTip}
                customTip={
                  typeof customTip === "string"
                    ? parseFloat(customTip)
                    : customTip
                }
                handleCustomTipChange={handleCustomTipChange}
                errorMessage={errorMessage}
                carterror={carterror}
                NoteContent={NoteContent}
                optionDelivery={optionDelivery}
                quoteId={quoteId}
                quoteProvider={quoteProvider}
                estimatedTime={estimatedTime}
                pickUp={pickUp}
                deliveryFee={deliveryFee}
                saveDelivery={handleSubmit(saveDelivery)}
                allProductPrice={allProductPrice}
                taxDisplayList={
                  Array.isArray(taxDisplayList)
                    ? taxDisplayList.map(String)
                    : []
                }
                taxDisplayTotal={
                  Array.isArray(taxDisplayTotal)
                    ? taxDisplayTotal.map(Number)
                    : []
                }
                finalTip={finalTip}
                finalDeliveryTip={finalDeliveryTip}
                storedOrderFee={storedOrderFee}
                storedOrderFeeLabel={storedOrderFeeLabel}
                totAmt={totAmt}
                deliveryAddress={deliveryAddress}
                isPaymentProcessing={isPaymentProcessing}
                isQuotesProcessing={isQuotesProcessing}
              />
            </div>
          </form>
        </FormProvider>
      </main>

      {isPopupVisible && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: -1,
            }}
          />
          <div>
            {isModalOpen ? (
              <div className="bg-white  max-w-4xl h-auto p-8 rounded-lg shadow-lg relative flex flex-col justify-center items-center">
                <button
                  className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-2xl font-bold"
                  onClick={() => {
                    setIsModalOpen(false);
                    setIsPopupVisible(false);
                  }}
                >
                  &times;
                </button>
                <h3 className="font-bold text-base mb-4 text-center">
                  Delivery Options
                </h3>
                <ul className="w-full space-y-4">
                  {deliveryOptions.map((option: DeliveryOption) => (
                    <li
                      key={option.id}
                      onClick={() => {
                        setIsModalOpen(false);
                        setIsPopupVisible(!isPopupVisible);
                        setQuoteId(option.id);
                        setQuoteProvider(option.provider);
                        setEstimatedTime(option.delivery_mins_from_now);
                        setPickUp(option.pickup_mins_from_now);
                        setDeliveryFee(`${option.price}`);
                        // setGreenFlag(true);
                      }}
                      className="bg-gray-100 hover:bg-gray-200 p-4 rounded-lg shadow cursor-pointer flex flex-col justify-center items-start"
                    >
                      <p className="font-semibold">
                        Provider : {option.provider}
                      </p>
                      <p>Price : ${option.price / 100}</p>
                      {/* <p>
                        Pickup Time: {option.pickup_mins_from_now} mins from now
                      </p> */}
                      <p>ETA : aprx. {option.delivery_mins_from_now} mins</p>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}

            {!isModalOpen && !greenFlag && isPopupVisible && (
              <div className="bg-white w-11/12 max-w-md h-auto p-8 rounded-lg shadow-lg relative flex flex-col justify-center items-center">
                <p className="font-bold text-sm text-center mb-4">{authNet}</p>
                <ButtonPrimary
                  onClick={togglePopup}
                  className="bg-black hover:bg-gray-700 text-white font-bold py-2 px-4 mt-4 rounded"
                >
                  Exit
                </ButtonPrimary>
              </div>
            )}

            {!isModalOpen &&
              greenFlag &&
              (somethingWrong === false ? (
                <div className="bg-white w-11/12 max-w-md h-auto p-8 rounded-lg shadow-lg relative flex flex-col justify-center items-center">
                  <h1 className="font-bold text-6xl mb-4 text-green-500 flex justify-center items-center">
                    <FaCheckCircle />
                  </h1>
                  <h3 className="font-bold text-base mb-4 text-center">
                    Order Placed Successfully
                  </h3>
                  <p className="font-bold text-sm text-center mb-4">
                    {authNet}
                  </p>
                  <div className="flex flex-col sm:flex-row sm:space-x-4 text-center">
                    <span>OrderNo: #{responseData?.orderNo}</span>
                    {/* <span>Amount: ${responseData?.totalAmount}</span> */}
                  </div>
                  {delivery === true && (
                    <span mt-4 className="text-sm text-center">
                      Your Order will be delivered by approximately {showTime}
                    </span>
                  )}
                  {delivery === false && (
                    <span mt-4 className="text-sm text-center">
                      Your Order will be ready by approximately{" "}
                      {formatPickupTime(pickUpTime)}
                    </span>
                  )}
                  <ButtonPrimary
                    onClick={togglePopup}
                    className="bg-black hover:bg-gray-700 text-white font-bold py-2 px-4 mt-4 rounded"
                  >
                    Exit
                  </ButtonPrimary>
                </div>
              ) : (
                <div className="bg-white w-11/12 max-w-md h-auto p-8 rounded-lg shadow-lg relative flex flex-col justify-center items-center">
                  <p className="font-bold text-sm text-center mb-4">
                    Something went wrong, please try again.
                  </p>
                  <ButtonPrimary
                    onClick={togglePopup}
                    className="bg-black hover:bg-gray-700 text-white font-bold py-2 px-4 mt-4 rounded"
                  >
                    Exit
                  </ButtonPrimary>
                </div>
              ))}
          </div>
        </div>
      )}
      {isPopupVisible === false && pause === "1" && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
          onClick={() => setPause(null)} // Close the box on overlay click
        >
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: -1,
            }}
          />
          <div>{<div className="alert alert-red">{pauselabel}</div>}</div>
        </div>
      )}
    </div>
  );
};

export default CheckoutPage;
