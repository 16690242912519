import { NoSymbolIcon, CheckIcon } from "@heroicons/react/24/outline";
import NcInputNumber from "components/NcInputNumber";
import Prices from "components/Prices";
import { useCartDataProvider } from "contaxt/CartProductContext";
import { Product } from "data/data";
import { useNavigate, useParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CheckoutPopup from "./CheckoutPopup";
import React, { useEffect, useState } from "react";
import { MdOutlineModeEdit } from "react-icons/md";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import { getMenuScheduleData } from "services/HomeService";
import toast from "react-hot-toast";
import { Transition } from "@headlessui/react";
import Page404 from "../Page404/Page404";
import NcImage from "shared/NcImage/NcImage";
const CartPage = () => {
  const { Products,setProducts, dispatch, searchProduct }: any = useCartDataProvider();
  
  const [pause, setPause] = useState<string | null>(null);
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [status, setStatus] = useState<string | null>(null);
  const [manuScheludeData, setManuScheludeData] = useState<any[]>([]);
  const MERCHANT_STORAGE_KEY = "merchantname";
  const merchantname = localStorage.getItem(MERCHANT_STORAGE_KEY);
  const userDataString = localStorage.getItem("userData");
  const SignupData = localStorage.getItem("SignupData");
 const [hasError, setHasError] = useState(false);
  const pauselabel =localStorage.getItem("onlineOrderSettingsPauseLabel");
  const blurStyle = {
    filter: isModalOpen ? "blur(8px)" : "none",
  };

  const TaxName = (item: Product) => {
    const { onlinePrice, quantity, note, taxes } = item;
    const MERCHANT_STORAGE_KEY = "merchantname";
    const merchantname = localStorage.getItem(MERCHANT_STORAGE_KEY);
    let basePrice = quantity ? 1 * quantity : onlinePrice;
    let taxAmt = 0;
    const taxname: string[] = [];
    if (taxes) {
      taxes.forEach((taxes) => {
        taxname.push(taxes.name);
      });
    }
    return taxname;
  };
  useEffect(() => {
    const pauseOrder = localStorage.getItem("pause");
    const merchantname = localStorage.getItem("merchantname");
    console.log(pauseOrder, ";llllll1", merchantname);
   
    setStatus(pauseOrder);
  });
  
  const TaxDetails = (item: Product) => {
    const { taxes } = item;
    const taxDetails: Record<string, string> = {};
    if (taxes) {
      taxes.forEach((tax) => {
        taxDetails[tax.name] = `${tax.rate},${tax.type}`;
      });
    }
    return taxDetails;
  };

  const taxDetails = Products.reduce(
    (accumulator: Record<string, string>, product: Product) => {
      const details = TaxDetails(product);
      Object.entries(details).forEach(([name, rateAndType]) => {
        if (!accumulator[name] || accumulator[name] !== rateAndType) {
          accumulator[name] = rateAndType;
        }
      });
      return accumulator;
    },
    {}
  );

  const taxDisplayList = Object.entries(taxDetails).map(
    ([name, rateAndType]) => {
      const [rate, type] = (rateAndType as string).split(",");
      if (type === "Percentage") {
        return `${name}: ${rate}%`;
      } else if (type === "Fixed") {
        return `${name}: ${rate}`;
      } else {
        return `${name}: ${rate}%`;
      }
    }
  );

  interface TaxTotal {
    name: string;
    rate: number;
    amount: number;
  }

  const calculatingTotalPrice = (item: Product) => {
    const { onlinePrice, quantity, note, modifierChecked, modifierSets } = item;
    let allPrice = onlinePrice;
    if (modifierSets) {
      modifierSets.forEach((modifierSets) => {
        const matchingKey =
          modifierChecked &&
          Object.keys(modifierChecked)?.find(
            (key) => Number(key) === modifierSets.id
          );
        if (matchingKey) {
          modifierSets.modifierSetModifierItems?.forEach((modifierItem) => {
            const modifierItemId = modifierItem.id.toString();
            if (modifierChecked[matchingKey]?.[modifierItemId] !== undefined) {
              allPrice += modifierChecked[matchingKey][modifierItemId]
                ? modifierItem.modifierItem.onlinePrice
                : 0;
            }
          });
        }
      });
    }
    let basePrice = quantity ? allPrice * quantity : allPrice;
    return basePrice;
  };

  const calculatingTotalTax = (item: Product): TaxTotal[] => {
    const { onlinePrice, quantity, taxes } = item;
    let basePrice = quantity ? 1 * quantity : onlinePrice;
    let taxTotals: TaxTotal[] = [];
    if (taxes) {
      taxes.forEach((tax) => {
        let totTaxAmt = 0;
        if (tax.type === "Percentage") {
          totTaxAmt = calculatingTotalPrice(item) * (tax.rate / 100);
        } else {
          totTaxAmt = basePrice * tax.rate;
        }
        taxTotals.push({ name: tax.name, rate: tax.rate, amount: totTaxAmt });
      });
    }
    return taxTotals;
  };

  const taxTotals: TaxTotal[] = Products.flatMap((product: any) =>
    calculatingTotalTax(product)
  );

  const taxDisplayTotal = Object.values(
    taxTotals.reduce((accumulator: Record<string, number>, taxTotal) => {
      const { name, amount } = taxTotal;
      accumulator[name] = (accumulator[name] || 0) + amount;
      return accumulator;
    }, {})
  ).map((totalAmount: number) => totalAmount.toFixed(2));

  const allProductPrice = Products.reduce(
    (accumulator: number, currentValue: Product) =>
      accumulator + calculatingTotalPrice(currentValue),
    0
  ).toFixed(2);

  const amtTax = Products.reduce(
    (accumulator: number, currentValue: Product) => {
      const taxTotals: TaxTotal[] = calculatingTotalTax(currentValue);
      const totalTaxForProduct = taxTotals.reduce(
        (totalTax: number, taxTotal: TaxTotal) => totalTax + taxTotal.amount,
        0
      );
      return accumulator + totalTaxForProduct;
    },
    0
  ).toFixed(2);

  const taxnames = Products.reduce(
    (accumulator: string[], currentValue: Product) => {
      return [...accumulator, TaxName(currentValue)];
    },
    []
  );

  const taxnamelist: Set<string> = new Set();

  taxnames.forEach((tax: string[]) => {
    tax.forEach((taxName: string) => {
      taxnamelist.add(taxName); // Add each tax name to the Set
    });
  });

  const totAmt = parseFloat(allProductPrice) + parseFloat(amtTax);
  const pad = (num: number) => (num < 10 ? "0" + num : num);
const convertToLocalTime = (time: string): string => {
  // Parse time string (HH:mm) as UTC
  const [hours, minutes] = time.split(":").map(Number);

  // Create a Date object in UTC
  const utcDate = new Date();
  utcDate.setUTCHours(hours, minutes, 0, 0);

  // Convert UTC time to local time (this uses the local timezone)
  const date = new Date(utcDate.toLocaleString("en-US", { timeZone: undefined }));
   const hourss = pad(date.getHours());
   const minutess = pad(date.getMinutes());
   return `${hourss}:${minutess}`;
};
  
const handleClick = () => {
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const findTodayDate = manuScheludeData.filter(
    (_item, index) => _item.menuDays === days[ new Date().getDay()]
  );
console.log(
  findTodayDate,
  convertToLocalTime(findTodayDate[0].fromTime),
  findTodayDate.length > 0,
  manuScheludeData,
  new Date().getDay(),
  ";llllll1"
);
  const pad = (num: number) => (num < 10 ? "0" + num : num);

  // Get current time in HH:mm format
  const now = new Date();
  const hour = pad(now.getHours());
  const minute = pad(now.getMinutes());
  const presentDate = `${hour}:${minute}`;
 
 

    // Extract times from schedule data (assumed format HH:mm)
    const fromTime =
      findTodayDate.length > 0
        ? convertToLocalTime(findTodayDate[0].fromTime).slice(0, 5)
        : "00:00";
    const toTime =
      findTodayDate.length > 0
        ? convertToLocalTime(findTodayDate[0].toTime).slice(0,5)
      : "00:00";
  console.log(
    presentDate,fromTime < presentDate,
    toTime > presentDate,
    ";llllll1"
  );
  const checkTime = fromTime < presentDate && toTime > presentDate;
  localStorage.setItem("checkTime", checkTime.toString());
   localStorage.setItem("fromTime", fromTime);
   localStorage.setItem("toTime", toTime);
    if (status !== "1") {
      const pauseOrder = localStorage.getItem("pause");
      console.log(localStorage, status !== "1", "pause");
      if (status !== "1" && (userDataString || SignupData)) {
        navigate(`/${merchantname}/checkout`);
      } else {
        navigate(`/${merchantname}/userdetails`);
      }
      setPause(pauseOrder);
    } else {
      toast.custom(
        (t) => (
          <Transition
            appear
            show={t.visible}
            className="p-4 max-w-md w-full bg-red-100 dark:bg-red-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-red-500/50 dark:ring-red-500/10 text-red-900 dark:text-red-200"
            enter="transition-all duration-150"
            enterFrom="opacity-0 translate-x-20"
            enterTo="opacity-100 translate-x-0"
            leave="transition-all duration-150"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-20"
          >
            <p className="block text-base font-semibold leading-none">
              Failed to Checkout Order
            </p>
            <div className="border-t border-red-200 dark:border-red-700 my-4" />
            Sorry, the restaurant is currently not accepting online orders.
          </Transition>
        ),
        { position: "top-right", id: "nc-product-notify", duration: 3000 }
      );
    }
  };
const clearCart = () => {
  console.log("Clearing cart...");
  dispatch({ type: "CLEAR_CART" });
};
  const renderProduct = (item: Product, index: number) => {
    const {
      imageUrl,
      name,
      id,
      quantity,
      note,
      modifierChecked,
      modifierSets,
      cartItemId,
    } = item;

    const handleQuantityChange = (newQuantity: number) => {
      const updatedItem = { ...item, quantity: newQuantity };
    };
    return (
      <div
        key={index}
        className="relative flex py-8 sm:py-10 xl:py-12 first:pt-0 last:pb-0"
      >
        <div className="relative h-32 w-32 sm:w-32 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <NcImage
            src={imageUrl}
            containerClassName="flex aspect-w-1 aspect-h-1 w-full h-0"
            className="w-full rounded-md object-cover"
          />
          <button
            onClick={() =>
              navigate(`/${merchantname}/product-detail/${id}`, {
                state: {
                  modifierChecked: modifierChecked || {},
                  cartItemId: cartItemId,
                  quantity: quantity,
                  note: note,
                },
              })
            }
            className="absolute inset-0"
          ></button>
        </div>
        <div className="ml-2 sm:ml-3 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div className="flex-[1.5] ">
                <h3 className="text-base font-semibold">{name}</h3>
                <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
                  <div className="flex items-center space-x-1.5"></div>
                  <span className="mx-4 border-l border-slate-200 dark:border-slate-700 "></span>
                  <div className="flex items-center space-x-1.5"></div>
                </div>

                <div className="mt-3 flex justify-between w-full sm:hidden relative">
                  <div className="sm text-center relative">
                    {/* for Moblie view */}
                    <NcInputNumber
                      className="relative z-10"
                      quantity={quantity}
                      note={note}
                      id={id}
                      modifierChecked={modifierChecked}
                      item={item}
                      onChange={handleQuantityChange}
                    />
                  </div>
                  <Prices
                    contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                    onlinePrice={calculatingTotalPrice(item)}
                  />
                </div>
              </div>

              <div className="hidden sm:block text-center relative">
                {/* for Desktop view */}
                <NcInputNumber
                  className="relative z-10"
                  quantity={quantity}
                  note={note}
                  id={id}
                  modifierChecked={modifierChecked}
                  item={item}
                  onChange={handleQuantityChange}
                />
              </div>

              <div className="hidden flex-1 sm:flex justify-end">
                <Prices
                  onlinePrice={calculatingTotalPrice(item)}
                  className="mt-0.5"
                />
              </div>
            </div>
          </div>

          {modifierSets?.map((item) => {
            const matchingKey =
              modifierChecked &&
              Object.keys(modifierChecked)?.find(
                (key) => Number(key) === item.id
              );
            if (matchingKey) {
              const selectedModifiers = item.modifierSetModifierItems?.filter(
                (modifierItem) =>
                  modifierChecked[matchingKey]?.[modifierItem.id.toString()]
              );
              // let totalPrice = 0;
              // selectedModifiers?.forEach((modifierItem) => {
              //   if (modifierItem.modifierItem.price !== 0) {
              //     totalPrice += modifierItem.modifierItem.price;
              //   }
              // });
              return (
                <div key={item.id} className="flex flex-col">
                  {/* {selectedModifiers?.length > 0 && (
                    <h1 className="text-sm font-bold">
                      {item.name}: ${totalPrice}
                    </h1>
                  )} */}
                  <div className="flex flex-wrap mt-2">
                    <h3 className="text-xs mr-1 ">
                      {selectedModifiers
                        ?.filter(
                          (modifierItem) =>
                            modifierItem.modifierItem.onlinePrice === 0
                        )
                        .map((modifierItem) => modifierItem.modifierItem.name)
                        .join(" , ")}
                    </h3>
                  </div>
                  <div className="mt-2">
                    {selectedModifiers?.map((modifierItem) => {
                      if (modifierItem.modifierItem.onlinePrice !== 0) {
                        return (
                          <h3
                            key={modifierItem.id}
                            className="text-xs flex justify-between pb-2"
                          >
                            <span>{modifierItem.modifierItem.name}</span>
                            <span className="text-slate-900 dark:text-slate-200">
                              ${modifierItem.modifierItem.onlinePrice}
                            </span>
                          </h3>
                        );
                      }
                      return null;
                    })}
                  </div>
                </div>
              );
            }
            return null;
          })}
          {note && (
            <div className="mt-4">
              <h3 className="text-sm font-bold">Item Note:</h3>
              <p className="text-xs text-slate-600 dark:text-slate-300 mt-1">
                {note}
              </p>
            </div>
          )}
          <div className="flex mt-auto pt-4 items-end justify-between text-sm w-full">
            <div className="flex ml-auto">
              <div
                onClick={() =>
                  navigate(`/${merchantname}/product-detail/${id}`, {
                    state: {
                      modifierChecked: modifierChecked || {},
                      cartItemId: cartItemId,
                      quantity: quantity,
                      note: note,
                    },
                  })
                }
                className="mr-3 cursor-pointer"
              >
                <MdOutlineModeEdit
                  style={{ color: "#7BB542", fontSize: "1.3rem" }}
                />
              </div>
              <div
                onClick={() =>
                  dispatch({
                    type: "REMOVE_ITEMS",
                    payload: item,
                  })
                }
                className="cursor-pointer"
              >
                <MdOutlineDeleteOutline
                  style={{ color: "#7BB542", fontSize: "1.3rem" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const getMenuSchedule = async () => {
      try {
        const response: any = await getMenuScheduleData();
        setManuScheludeData(response.data);
      } catch (error) {
        console.log("error", error);
      }
    };
    getMenuSchedule();
  }, []);
  console.log(Products, "Products");
  return (
    <div className="nc-CartPage">
      <div style={blurStyle}>
        {Products.length != 0 ? (
          <main className={`container py-10 lg:pb-28 lg:pt-20 `}>
            <div className="mb-10 sm:mb-10">
              <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
                Your Order
              </h2>
              <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
                <Link to={`/${merchantname}`} className="">
                  Menu
                </Link>
                <span className="text-xs mx-1 sm:mx-1.5">/</span>
                <span className="underline">Cart</span>
                {!userDataString && !SignupData && (
                  <span className="text-xs mx-1 sm:mx-1.5">/</span>
                )}
                {!userDataString && !SignupData && (
                  <Link to={`/${merchantname}/userdetails`} className="">
                    User Details
                  </Link>
                )}
                {/* <span className="text-xs mx-1 sm:mx-1.5">/</span>
              <Link to={`/checkout/${merchantname}`} className="">
              Checkout
              </Link> */}
              </div>
            </div>

            <hr className="border-slate-200 dark:border-slate-700 my-10 xl:my-12" />

            <div className="flex flex-col lg:flex-row">
              <div className="w-full lg:w-[60%] xl:w-[55%] divide-y divide-slate-200 dark:divide-slate-700 ">
                {/* {Products?.map(renderProduct)} */}
                {Products?.filter((item: any) =>
                  item.name.toLowerCase().includes(searchProduct.toLowerCase())
                ).map(renderProduct)}
                <ButtonPrimary onClick={() => clearCart()}>
                  Clear Cart
                </ButtonPrimary>
              </div>

              <div className="border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:mx-16 2xl:mx-20 flex-shrink-0"></div>
              <div className="flex-1">
                <div className="sticky top-28">
                  <h3 className="text-lg font-semibold ">Order Summary</h3>
                  <div className="mt-7 text-sm text-slate-500 dark:text-slate-400 divide-y divide-slate-200/70 dark:divide-slate-700/80">
                    <div className="flex justify-between pb-4">
                      <span>Subtotal</span>
                      <span className="font-semibold text-slate-900 dark:text-slate-200">
                        ${allProductPrice}
                      </span>
                    </div>

                    <div>
                      {taxDisplayList.map((taxDisplay, index) => (
                        <div key={index} className="flex justify-between py-2">
                          <span>{taxDisplay}</span>
                          <span className="font-semibold text-slate-900 dark:text-slate-200">
                            ${taxDisplayTotal[index]}
                          </span>
                        </div>
                      ))}
                    </div>
                    <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                      <span>Order Total</span>
                      <span>${totAmt.toFixed(2)}</span>
                    </div>
                  </div>
                  <div className="flex justify-center">
                    {
                      <ButtonPrimary onClick={handleClick} className="mt-8">
                        Checkout
                      </ButtonPrimary>
                    }
                  </div>
                </div>
              </div>
            </div>
          </main>
        ) : (
          <main className={`container py-16 lg:pb-28 lg:pt-20 `}>
            <div className="mb-12 sm:mb-16">
              <h2 className="mb-8 block text-2xl sm:text-1xl lg:text-2xl font-semibold ">
                Your cart is currently empty
              </h2>
              <h3 className="text-base ">
                Browse our menu to start adding items to your cart
              </h3>
              <div className="flex justify-center">
                <ButtonPrimary href={`/${merchantname}`} className="mt-8">
                  Browse Menu
                </ButtonPrimary>
              </div>
            </div>
          </main>
        )}
      </div>
      <CheckoutPopup isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
      {pause === "1" && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
          onClick={() => setPause(null)} // Close the box on overlay click
        >
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: -1,
            }}
          />
          <div>{<div className="alert alert-red">{pauselabel}</div>}</div>
        </div>
      )}
    </div>
  );
};

export default CartPage;
