import React from "react";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
export interface CommonLayoutProps {
  children?: React.ReactNode;
}

const CommonLayout: FC<CommonLayoutProps> = ({ children }) => {
  const userDataString = localStorage.getItem("userData");
  const SignupData = localStorage.getItem("SignupData");
  const CustomerAdded =localStorage.getItem("customerAdded");
  const Info=localStorage.getItem("CustomerInfo");
  const [userData, setUserData] = useState<any>(null);
  const [customerInfo,setCustomerInfo]=useState<any>(null)
  const merchantname = localStorage.getItem("merchantname");
  console.log(localStorage.getItem("CustomerInfo"), "userdatatest");
  useEffect(() => {
    const fetchDataFromApi = async () => {
      try {
        // Make the API call to fetch data
        if (userDataString && Info &&!SignupData) {
         
          console.log( userDataString,Info,"userdatatest222");
          const response = JSON.parse(Info);
          // Assuming the response is an object with a "Customer Info" property
          setUserData(response);
          
          console.log(response, "userdataa");
        } else if (SignupData && CustomerAdded) {
          const response = JSON.parse(CustomerAdded);
          console.log(response, "checkkkkk");
          setUserData(response);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call fetchDataFromApi if userData is not available in localStorage
    if (!userData) {
      fetchDataFromApi();
    }
  }, [userDataString, SignupData, CustomerAdded, Info]);
  return (
    <div className="nc-CommonLayoutProps container">
      <div className="mt-8 sm:mt-20">
        <div className="max-w-4xl mx-auto">
          <div className="max-w-2xl">
            <h2 className="text-2xl xl:text-2xl font-semibold">My Account</h2>
            <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-base sm:text-lg">
              <span className="text-slate-900 dark:text-slate-200 font-semibold">
                {userData?.firstName} {userData?.lastName},
              </span>{" "}
              {userData?.email}
            </span>
          </div>
          <hr className="mt-8 border-slate-200 dark:border-slate-700"></hr>

          <div className="flex space-x-8 md:space-x-14 overflow-x-auto hiddenScrollbar">
            {[
              {
                name: "Account info",
                link: `/${merchantname}/account`,
              },
              {
                name: "Payment methods",
                link: `/${merchantname}/account-savelists`,
              },
              {
                name: " Order history",
                link: `/${merchantname}/account-my-order`,
              },
              // {
              //   name: "Change password",
              //   link: `/${merchantname}/account-change-password`,
              // },
            ].map((item, index) => (
              <NavLink
                key={index}
                to={item.link}
                className={({ isActive }) =>
                  `block py-5 md:py-8 border-b-2 border-transparent flex-shrink-0  text-sm sm:text-base ${
                    isActive
                      ? "border-primary-500 font-medium text-slate-900 dark:text-slate-200"
                      : "text-slate-500 dark:text-slate-400 hover:text-slate-800 dark:hover:text-slate-200"
                  }`
                }
              >
                {item.name}
              </NavLink>
            ))}
          </div>
          <hr className="border-slate-200 dark:border-slate-700"></hr>
        </div>
      </div>
      <div className="max-w-4xl mx-auto pt-10 sm:pt-26 pb-24 lg:pb-32">
        {children}
      </div>
    </div>
  );
};

export default CommonLayout;
