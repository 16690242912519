import React, { FC } from "react";

export interface PricesProps {
  className?: string;
  onlinePrice?: any;
  unit?: string;
  priceType?: string;
  contentClass?: string;
}

const Prices: FC<PricesProps> = ({
  className = "",
  onlinePrice,
  unit = "",
  priceType = "",
  contentClass = "py-1 px-24 md:py-1 md:px-2.5 text-sm font-medium",
}) => {
  return (
    <div className={`flex justify-center items-center ${className}`}> {/* Centering the Prices component */}
    <div className={`flex flex-col items-center ${contentClass}`}>
      <span >
        ${onlinePrice?.toFixed(2)} 
      </span>
    </div>
  </div>
  );
};

export default Prices;
