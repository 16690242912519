

import React, { useState } from "react";
import { HiOutlineCog } from "react-icons/hi";
import SidePanelContent from "./SIdePanelContent";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import SwitchDarkMode2 from "shared/SwitchDarkMode/SwitchDarkMode2";
import { Drawer } from "@mui/material";


function Sidepanel() {
  const [isOpen, setIsOpen] = useState(false);


  return (
    <>
      <div className="text-2xl" onClick={()=>setIsOpen(true)}>
        <div className="w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none flex items-center justify-center cursor-pointer">
        <HiOutlineCog />
        </div>
      </div>
      <Drawer anchor="right" open={isOpen}   sx={{ '& .MuiDrawer-paper': { width: '300px' } }}>
        <SidePanelContent setIsOpen={setIsOpen}/>
      </Drawer>
    </>
  );
}

export default Sidepanel;
