import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LikeButton from "components/LikeButton";
import BagIcon from "components/BagIcon";
import NcInputNumber from "components/NcInputNumber";
import { PRODUCTS, Product } from "data/data";
import {
  NoSymbolIcon,
  ClockIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import IconDiscount from "components/IconDiscount";
import Prices from "components/Prices";
import toast from "react-hot-toast";
import ModalViewAllReviews from "./ModalViewAllReviews";
import { apiGetItemData } from "services/MenuService";
import { useLocation, useParams } from "react-router-dom";
import { Disclosure, Transition } from "@headlessui/react";
import Checkbox from "shared/Checkbox/Checkbox";
import NcImage from "shared/NcImage/NcImage";
import { useCartDataProvider } from "contaxt/CartProductContext";
import { FaAngleDown } from "react-icons/fa";
import { FaAngleUp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
export interface ProductDetailPageProps {
  className?: string;
}

const ProductDetailPage: FC<ProductDetailPageProps> = ({ className = "" }) => {
  const { status } = PRODUCTS[0];
  const location = useLocation();
  const [qualitySelected, setQualitySelected] = React.useState(1);
  const [isOpenModalViewAllReviews, setIsOpenModalViewAllReviews] =
    useState(false);
  const [requiredCheck, setRequiredCheck] = useState(false);
  const [showError, setShowError] = useState(false);
  const [itemData, setItemData] = useState<Product[]>([]);
  const [checkedItem, setCheckedItem] = useState<Record<number, boolean>>({});
  const [modifierCheckedDatastate, setModifierCheckedDatastate] = useState<any>(
    []
  );

  const MERCHANT_STORAGE_KEY = "merchantname";
  const merchantname = localStorage.getItem(MERCHANT_STORAGE_KEY);
  const { dispatch } = useCartDataProvider();
  const paramId: any = useParams();
  const id: any = paramId.id ? parseInt(paramId.id) : undefined;
  const navigate = useNavigate();
  console.log("location.state", location.state);
  const handleNoteChange = (event: any, index: any) => {
    const newNote = event.target.value;
    const updatedItems = [...itemData];
    updatedItems[index] = {
      ...updatedItems[index],
      note: newNote,
    };
    setItemData(updatedItems);

    if (location.state) {
      dispatch({
        type: "EDIT_CART",
        payload: {
          ...updatedItems[index],
          note: newNote,
          cartItemId: location.state.cartItemId,
        },
      });
    }
  };

  const handleCheckboxChange = (
    checked: boolean,
    id: number,
    item: any,
    nameid: number
  ) => {
    setModifierCheckedDatastate((prev: any) => {
      const updatedState = { ...prev };
      if (!updatedState[nameid]) {
        updatedState[nameid] = {};
      }
      updatedState[nameid][id] = checked;
      if (!checked) {
        delete updatedState[nameid][id];
      }
      return updatedState;
    });
  };

  useEffect(() => {
    const getItemData = async () => {
      try {
        const res = await apiGetItemData();
        const data = res.data as any;
        const items = data.items.map((items: any) => items.items);
        const orderFee = data.onlineOrderSettings.onlineOrderFee;
        const orderFeeLabel = data.onlineOrderSettings.onlineOrderFeeLabel;
        localStorage.setItem("orderFee", orderFee);
        localStorage.setItem("orderFeeLabel", orderFeeLabel);
        const flattenedItems = items.flatMap((innerArray: any) => innerArray);
        const responseData = flattenedItems as Product[];
        const filteredData = responseData
          .map((item) => ({
            ...item,
            quantity: location.state ? location.state.quantity : 1,
          }))
          .filter((item: Product) => item.id === parseInt(id));

        let uniqueIds = new Set();
        const result = filteredData.filter((item: any) => {
          if (!uniqueIds.has(item.id)) {
            uniqueIds.add(item.id);
            return true;
          }
          return false;
        });

        if (location.state) {
          const modifiedResult = result.map((item) => ({
            ...item,
            modifierChecked: location.state.modifierChecked,
            cartItemId: location.state.cartItemId,
            note: location.state.note,
          }));
          const quantity = location.state.quantity;
          setQualitySelected(quantity);
          setItemData(modifiedResult);
        } else {
          setItemData(result);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getItemData();
  }, [location.state]);

  useEffect(() => {
    const modifierCheckedData = itemData.map((ele) => ({
      ...ele,
      modifierChecked: modifierCheckedDatastate,
    }));
    setItemData(modifierCheckedData);
  }, [modifierCheckedDatastate, location.state]);

  useEffect(() => {
    const extractedValues: any = {};
    for (const key in location.state?.modifierChecked) {
      const innerObj = location.state?.modifierChecked[key];
      for (const innerKey in innerObj) {
        extractedValues[innerKey] = innerObj[innerKey];
      }
    }
    if (location.state) {
      setCheckedItem((prevCheckedItem) => ({
        ...prevCheckedItem,
        ...extractedValues,
      }));
      setModifierCheckedDatastate(location.state.modifierChecked);
    }
  }, [location.state]);

  useEffect(() => {
    const validateRequiredModifiers = itemData.every((item) =>
      item.modifierSets?.every((modify) => {
        const selectedCount = Object.values(
          modifierCheckedDatastate[modify.id] || {}
        ).filter(Boolean).length;
        return selectedCount >= (modify.noOfRequired || 0);
      })
    );

    const validateMaximumModifiers = itemData.every((item) =>
      item.modifierSets?.every((modify) => {
        const selectedCount = Object.values(
          modifierCheckedDatastate[modify.id] || {}
        ).filter(Boolean).length;
        return !modify.maximum || selectedCount <= modify.maximum;
      })
    );

    if (itemData[0]?.modifierSets) {
      setRequiredCheck(validateRequiredModifiers && validateMaximumModifiers);
    } else {
      setRequiredCheck(true);
    }
  }, [modifierCheckedDatastate, itemData]);

  const notifyAddTocart = () => {
    toast.custom(
      (t) => (
        <Transition
          appear
          show={t.visible}
          className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
          enter="transition-all duration-150"
          enterFrom="opacity-0 translate-x-20"
          enterTo="opacity-100 translate-x-0"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-20"
        >
          <p className="block text-base font-semibold leading-none">
            Added to cart!
          </p>
          <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
          {renderProductCartOnNotify()}
        </Transition>
      ),
      { position: "top-right", id: "nc-product-notify", duration: 1000 }
    );
  };

  const renderProductCartOnNotify = () => {
    const item = itemData[0];
    let allPrice = itemData[0]?.onlinePrice;
    if (item.modifierSets) {
      item?.modifierSets.forEach((modifier) => {
        const matchingKey =
          item.modifierChecked &&
          Object.keys(item.modifierChecked)?.find(
            (key) => Number(key) === modifier.id
          );
        if (matchingKey) {
          modifier.modifierSetModifierItems?.forEach((modifierItems) => {
            const modifierItemId = modifierItems.id.toString();
            if (
              item.modifierChecked[matchingKey]?.[modifierItemId] !== undefined
            ) {
              allPrice += item.modifierChecked[matchingKey][modifierItemId]
                ? modifierItems.modifierItem.onlinePrice
                : 0;
            }
          });
        }
      });
    }
    return (
      <>
        {itemData.map((item, index) => (
          <div key={index} className="flex ">
            <div className="h-20 w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
              {/* <img
                src={item.imageUrl}
                alt={item.name}
                className="h-full w-full object-cover object-center"
              /> */}
              <NcImage
                src={item.imageUrl}
                containerClassName="flex aspect-w-1 aspect-h-1 w-full h-0"
                className="w-full rounded-md object-cover"
              />
            </div>

            <div className="ml-4 flex flex-1 flex-col">
              <div>
                <div className="flex justify-between ">
                  <div>
                    <h3 className="text-base font-medium ">{item.name}</h3>
                    <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                      {/* <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                          <span>
                            {item.isAvailable ? "Available" : "Unavailable"}
                          </span> */}
                    </p>
                  </div>
                  <Prices
                    onlinePrice={allPrice}
                    unit={item.unit?.name}
                    priceType={item.priceType?.name}
                    className="mt-0.5"
                  />
                </div>
              </div>
              <div className="flex flex-1 items-end justify-between text-sm">
                <p className="text-gray-500 dark:text-slate-400">
                  Qty {itemData[0].quantity}
                </p>

                <div className="flex">
                  {/* <Link
                        to={`/cart/${merchantname}`}
                        className="font-medium text-primary-6000 dark:text-primary-500"
                      >
                        View cart
                      </Link> */}
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  const renderStatus = () => {
    if (!status) {
      return null;
    }
    const CLASSES =
      "absolute top-3 left-3 px-2.5 py-1.5 text-xs bg-white dark:bg-slate-900 nc-shadow-lg rounded-full flex items-center justify-center text-slate-700 text-slate-900 dark:text-slate-300";
    if (status === "New in") {
      return (
        <div className={CLASSES}>
          <SparklesIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "50% Discount") {
      return (
        <div className={CLASSES}>
          <IconDiscount className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "Sold Out") {
      return (
        <div className={CLASSES}>
          <NoSymbolIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "limited edition") {
      return (
        <div className={CLASSES}>
          <ClockIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    return null;
  };

  const descriptionContent = () => {
    return (
      <>
        {itemData.map((item, index) => (
          <div key={index} className="w-full rounded-2xl space-y-2.5">
            {item.description ? (
              <h3 className="flex items-center justify-between w-full px-4 py-2 font-medium text-left bg-slate-100/80 hover:bg-slate-200/60 dark:bg-slate-800 dark:hover:bg-slate-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75 ">
                {item.description}
              </h3>
            ) : (
              ""
            )}
          </div>
        ))}
      </>
    );
  };

  const NoteContent = () => {
    return (
      <>
        {itemData.map((item, index) => (
          <div key={index} className="w-full rounded-2xl space-y-2.5">
            <h3 className="flex items-center justify-between w-full px-4 py-2 font-medium text-left bg-slate-100/80 hover:bg-slate-200/60 dark:bg-slate-800 dark:hover:bg-slate-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75 ">
              Add Note
            </h3>
            <textarea
              placeholder=""
              className="w-full h-20 px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-slate-500 focus:ring-opacity-75 dark:text-gray-100 dark:bg-gray-700"
              value={item.note || ""}
              onChange={(event) => handleNoteChange(event, index)}
            />
          </div>
        ))}
      </>
    );
  };

  const modifierSetsContent = () => {
    return (
      <>
        {itemData.map((item, index) => (
          <div key={index} className="w-full rounded-2xl space-y-2.5">
            {item?.modifierSets?.map((modifier, index) => {
              const modifierState =
                modifierCheckedDatastate?.[modifier?.id] || {};
              const selectedCount =
                Object.values(modifierState).filter(Boolean).length;
              const isRequiredError = selectedCount < modifier.noOfRequired;
              const isMaximumError =
                modifier.maximum && selectedCount > modifier.maximum;
              const defaultOpenPanel =
                location.state?.modifierChecked &&
                Object.keys(location.state.modifierChecked).some(
                  (ele) =>
                    Number(ele) === modifier.id &&
                    Object.values(location.state.modifierChecked[ele]).some(
                      (mark) => mark === true
                    )
                );
              return (
                <Disclosure key={index} defaultOpen={defaultOpenPanel}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex items-center justify-between w-full px-4 py-2 font-medium text-right bg-slate-100/80 hover:bg-slate-200/60 dark:bg-slate-800 dark:hover:bg-slate-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75 ">
                        <span>
                          {modifier.name}{" "}
                          {modifier.noOfRequired > 0 && (
                            <span className="text-sm font-normal ml-auto">
                              (required {modifier.noOfRequired})
                            </span>
                          )}
                          {modifier.noOfRequired === 0 && (
                            <span className="text-sm font-normal ml-auto">
                              (optional)
                            </span>
                          )}
                        </span>

                        {modifier.noOfRequired > 0 && (
                          <span className="text-sm font-normal ml-auto">
                            {" "}
                            upto {modifier.maximum}{" "}
                          </span>
                        )}
                        {modifier.noOfRequired === 0 && (
                          <span className="text-sm font-normal ml-auto">
                            optional, upto {modifier.maximum}
                          </span>
                        )}
                        {/* <span className="text-sm">upto {modifier.maximum}</span> */}
                        {!open ? (
                          <FaAngleDown className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                        ) : (
                          <FaAngleUp className="w-4 h-4 tezxt-slate-600 dark:text-slate-400" />
                        )}
                      </Disclosure.Button>
                      {showError && (
                        <>
                          {isRequiredError && (
                            <p className="text-red-500">
                              Please select at least {modifier.noOfRequired}{" "}
                              modifier{modifier.noOfRequired > 1 ? "s" : ""}.
                            </p>
                          )}
                          {isMaximumError && (
                            <p className="text-red-500">
                              Please select at most {modifier.maximum}{" "}
                              modifiers.
                            </p>
                          )}
                        </>
                      )}
                      {modifier?.modifierSetModifierItems?.map(
                        (modifierCheck, index) => (
                          <Disclosure.Panel
                            key={index}
                            className="p-4 pt-3 last:pb-0 text-slate-600 text-sm dark:text-slate-300 leading-6 flex items-center justify-between"
                            as="div"
                          >
                            <label
                              className="flex items-center justify-between w-full cursor-pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                handleCheckboxChange(
                                  !modifierCheckedDatastate[modifier.id]?.[
                                    modifierCheck.id
                                  ],
                                  modifierCheck.id as number,
                                  modifierCheck.modifierItem?.name as string,
                                  modifier.id
                                );
                              }}
                            >
                              <div className="flex items-center">
                                <Checkbox
                                  key={modifierCheck.id}
                                  sizeClassName="w-5 h-5"
                                  labelClassName="text-sm font-normal"
                                  label={modifierCheck.modifierItem?.name}
                                  name={modifierCheck.id}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      e,
                                      modifierCheck.id as number,
                                      modifierCheck.modifierItem
                                        ?.name as string,
                                      modifier.id
                                    );
                                  }}
                                  defaultChecked={
                                    checkedItem[modifierCheck.id as number]
                                      ? true
                                      : false
                                  }
                                  checked={
                                    !!modifierCheckedDatastate[modifier.id]?.[
                                      modifierCheck.id
                                    ]
                                  }
                                />
                              </div>
                              <span>
                                ${modifierCheck.modifierItem?.onlinePrice}
                              </span>
                            </label>
                          </Disclosure.Panel>
                        )
                      )}
                    </>
                  )}
                </Disclosure>
              );
            })}
          </div>
        ))}
      </>
    );
  };

  const renderSectionContent = () => {
    return (
      <div className="space-y-7 2xl:space-y-8">
        {itemData.map((item) => (
          <div key={item.id}>
            <h2 className="text-2xl sm:text-3xl font-semibold">{item.name}</h2>
            <div className="flex items-center mt-5 space-x-4 sm:space-x-5">
              <Prices
                contentClass="py-1 px-2 md:py-1.5 md:px-3 text-lg font-semibold"
                // price={
                //   itemData[0].quantity
                //     ? item.price * itemData[0].quantity
                //     : item.price
                // }
                onlinePrice={item.onlinePrice}
                unit={item.unit?.name}
                priceType={item.priceType?.name}
              />
              {/* <div className="h-7 border-l border-slate-300 dark:border-slate-700"></div> */}
              <div className="flex items-center">
                {/* {item.isAvailable ? (
                  <span>Available</span>
                ) : (
                  <span>Unavailable</span>
                )} */}
                {/* <span className="hidden sm:block mx-2.5">·</span>
                <div className="hidden sm:flex items-center text-sm">
                  <SparklesIcon className="w-3.5 h-3.5" />
                  <span className="ml-1 leading-none">{status}</span>
                </div> */}
              </div>
            </div>
          </div>
        ))}
        {/* ---------- DESCRIPTION ----------  */}
        <div className="flex space-x-3.5">
          <div className="flex items-center justify-center bg-slate-100/70 dark:bg-slate-800/70 px-2 py-3 sm:p-3.5 rounded-full">
            <NcInputNumber
              defaultValue={qualitySelected}
              onChange={setQualitySelected}
              id={id}
              quantity={itemData[0].quantity}
              note={itemData[0].note}
              setItemData={setItemData}
              itemData={itemData}
            />
          </div>
          <ButtonPrimary
            className={`flex-1 flex-shrink-0 ${
              !itemData.some((item) => item.isAvailable)
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-black hover:bg-black"
            }`}
            onClick={() => {
              const allModifierSetsEmpty = itemData.every(
                (item: any) => item.modifierSets.length === 0
              );

              if (allModifierSetsEmpty) {
                if (location.state) {
                  dispatch({
                    type: "EDIT_CART",
                    payload: {
                      ...itemData[0],
                      cartItemId: location.state.cartItemId,
                    },
                  });
                } else {
                  dispatch({ type: "ADD_TO_CART", payload: itemData[0] });
                }
                notifyAddTocart();
              } else {
                if (requiredCheck) {
                  if (location.state) {
                    dispatch({
                      type: "EDIT_CART",
                      payload: {
                        ...itemData[0],
                        cartItemId: location.state.cartItemId,
                      },
                    });
                  } else {
                    dispatch({ type: "ADD_TO_CART", payload: itemData[0] });
                  }
                  notifyAddTocart();
                  setShowError(false);
                } else {
                  setShowError(true);
                }
              }
              console.log(showError, "show");
              if (requiredCheck) {
                navigate(`/${merchantname}`);
              }
            }}
            disabled={!itemData.some((item) => item.isAvailable)}
          >
            <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
            {location.state ? (
              <span className="ml-3">Save Changes</span>
            ) : (
              <span className="ml-3">Add to cart</span>
            )}
          </ButtonPrimary>
        </div>

        <hr className=" 2xl:!my-10 border-slate-200 dark:border-slate-700"></hr>

        {descriptionContent()}
        {/*ModifierContent */}
        {modifierSetsContent()}
        {NoteContent()}
        <ButtonPrimary
          className={`flex-1 flex-shrink-0 ${
            !itemData.some((item) => item.isAvailable)
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-black hover:bg-black"
          }`}
          onClick={() => {
            const allModifierSetsEmpty = itemData.every(
              (item: any) => item.modifierSets.length === 0
            );

            if (allModifierSetsEmpty) {
              if (location.state) {
                dispatch({
                  type: "EDIT_CART",
                  payload: {
                    ...itemData[0],
                    cartItemId: location.state.cartItemId,
                  },
                });
              } else {
                dispatch({ type: "ADD_TO_CART", payload: itemData[0] });
              }
              notifyAddTocart();
            } else {
              if (requiredCheck) {
                if (location.state) {
                  dispatch({
                    type: "EDIT_CART",
                    payload: {
                      ...itemData[0],
                      cartItemId: location.state.cartItemId,
                    },
                  });
                } else {
                  dispatch({ type: "ADD_TO_CART", payload: itemData[0] });
                }
                notifyAddTocart();
                setShowError(false);
              } else {
                setShowError(true);
              }
            }
            if (requiredCheck) {
              navigate(`/${merchantname}`);
            }
          }}
          disabled={!itemData.some((item) => item.isAvailable)}
        >
          <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
          {location.state ? (
            <span className="ml-3">Save Changes</span>
          ) : (
            <span className="ml-3">Add to cart</span>
          )}
        </ButtonPrimary>
        <div className="hidden xl:block">{/* <Policy /> */}</div>
      </div>
    );
  };

  return (
    <>
      {itemData.length !== 0 && (
        <div className={`nc-ProductDetailPage ${className}`}>
          <main className="container mt-5 mb-5 lg:mt-11">
            <div className="lg:flex">
              {itemData.map((item, index) => (
                <div key={index} className="w-full lg:w-[55%] ">
                  <div className="relative">
                    <div className="aspect-w-4 aspect-h-4">
                      <NcImage
                        src={item.imageUrl}
                        containerClassName="flex aspect-w-1 aspect-h-1 w-full h-0"
                        className="w-full rounded-md object-cover"
                      />
                    </div>

                    <LikeButton className="absolute right-3 top-3 " />
                  </div>
                </div>
              ))}
              <div className="w-full lg:w-[45%] pt-10 lg:pt-0 lg:pl-7 xl:pl-9 2xl:pl-10">
                {renderSectionContent()}
              </div>
            </div>
            <div className="mt-12 sm:mt-16 space-y-10 sm:space-y-16">
              <div className="block xl:hidden">{/* <Policy /> */}</div>
            </div>
          </main>
          <ModalViewAllReviews
            show={isOpenModalViewAllReviews}
            onCloseModalViewAllReviews={() =>
              setIsOpenModalViewAllReviews(false)
            }
          />
        </div>
      )}
    </>
  );
};

export default ProductDetailPage;
