import React, { FC, useEffect, useState } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import AvatarDropdown from "./AvatarDropdown";
import Navigation from "shared/Navigation/Navigation";
import CartDropdown from "./CartDropdown";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAllCategoryNameData } from "services/HomeService";
import { CategoryData } from "data/data";
import { useCartDataProvider } from "../../contaxt/CartProductContext";
import { BiFoodMenu } from "react-icons/bi";
import { getOnlineOrderSettings } from "services/HomeService";
import Page404 from "containers/Page404/Page404";
import Sidepanel from "./Sidepanel";
export interface MainNav2LoggedProps {}
interface OnlineOrderSettings {
  logo?: string;
  orderLeadTime?: number;
  image?: string;
  titleText?: string;
  customTextOne?: string;
  pauseOnlineOrder?: boolean;
  pauseOnlineOrderLabel?: string;
  address?: string;
  merchantName?: string;
  onlineOrderEmail?: string;
  phn?: string;
  onlineDeliveryId: string;
  pickUpTime: string;
  isDelivery?: boolean;

  // Add other properties if necessary
}
const MainNav2Logged: FC<MainNav2LoggedProps> = () => {
  const inputRef = React.createRef<HTMLInputElement>();
  const [showSearchForm, setShowSearchForm] = useState(false);
  const [categoryData, setCategoryData] = useState<CategoryData[]>([]);
  const { searchProduct, setSearchProduct }: any = useCartDataProvider();
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const MERCHANT_STORAGE_KEY = "merchantname";
  const [hasError, setHasError] = useState(false);
  const merchantname = localStorage.getItem(MERCHANT_STORAGE_KEY);
  const location = useLocation();
  const pathname = location.pathname;
  const merchant = pathname.split("/")[1];
  console.log(merchant === merchantname, "name");
  const [onlineData, setOnlineData] = useState<OnlineOrderSettings>();
  const pauselabel = localStorage.getItem("onlineOrderSettingsPauseLabel");
  const [pause, setPause] = useState<string | null>(null);
  // const getAllCategoryName = async () => {
  //   try {
  //     const allCategoryRes: { data: { items: any[], onlineOrderSettings: any[] } } =
  //       await getAllCategoryNameData();
  //     setCategoryData(allCategoryRes.data.items as CategoryData[]);
  //     setOnlineData(allCategoryRes.data.onlineOrderSettings as OnlineOrderSettings);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const allCategoryRes: {
          data: { items: any[]; onlineOrderSettings: OnlineOrderSettings };
        } = await getAllCategoryNameData();
        const onlinesettings = allCategoryRes.data.onlineOrderSettings;
        console.log(onlinesettings, "onlinesettings");

        if (
          allCategoryRes.data.items?.length === 0 
         
        ) {
          setHasError(true);
        } else {
          setCategoryData(allCategoryRes.data.items as CategoryData[]);
          setOnlineData(
            allCategoryRes.data.onlineOrderSettings as OnlineOrderSettings
          );

          localStorage.removeItem("pause");
          if ((onlinesettings as OnlineOrderSettings)?.pauseOnlineOrder) {
            const pauseOrder = localStorage.setItem(
              "pause",
              String(
                (onlinesettings as OnlineOrderSettings).pauseOnlineOrder
              )
            );
            setPause(
              String(
                (onlinesettings as OnlineOrderSettings).pauseOnlineOrder
              ) ?? null
            );
          }
          if (
            (onlinesettings as OnlineOrderSettings)?.pauseOnlineOrderLabel
          ) {
            localStorage.setItem(
              "onlineOrderSettingsPauseLabel",
              String(
                (onlinesettings as OnlineOrderSettings)
                  .pauseOnlineOrderLabel
              )
            );
          }
           if ((onlinesettings as OnlineOrderSettings)?.address) {
             localStorage.setItem(
               "merchantAddress",
               String((onlinesettings as OnlineOrderSettings).address)
             );
           }
           if ((onlinesettings as OnlineOrderSettings)?.merchantName) {
             localStorage.setItem(
               "merchantOnlineName",
               String((onlinesettings as OnlineOrderSettings).merchantName)
             );
           }
           if ((onlinesettings as OnlineOrderSettings)?.onlineOrderEmail) {
             localStorage.setItem(
               "onlineOrderEmail",
               String(
                 (onlinesettings as OnlineOrderSettings).onlineOrderEmail
               )
             );
           }
           if ((onlinesettings as OnlineOrderSettings)?.phn) {
             localStorage.setItem(
               "phone",
               String((onlinesettings as OnlineOrderSettings).phn)
             );
          }
           if ((onlinesettings as OnlineOrderSettings)?.onlineDeliveryId) {
             localStorage.setItem(
               "onlineDeliveryId",
               String((onlinesettings as OnlineOrderSettings).onlineDeliveryId)
             );
          }
         if ((onlinesettings as OnlineOrderSettings)?.onlineDeliveryId) {
           localStorage.setItem(
             "isDelivery",
             onlinesettings?.isDelivery?.toString() ?? "false"
           );
          }
          //  if ((onlinesettings as OnlineOrderSettings)?.pickUpTime) {
          //    localStorage.setItem(
          //      "pickUpTime",
          //      String((onlinesettings as OnlineOrderSettings).pickUpTime)
          //    );
          //  }
        }
      } catch (error) {
        setHasError(true);
        console.error("Error fetching online order settings:", error);
      }
    };
    fetchData();
  }, []);

  const { id } = useParams();

useEffect(() => {
  console.log(hasError, "hasError");
  const MERCHANT_STORAGE_KEY = "merchantname";

  const merchantname = localStorage.getItem(MERCHANT_STORAGE_KEY);
 if (hasError === true) {
   navigate(`/${merchantname}/Page404`);
 }
}, [hasError===true]);
  useEffect(() => {
    console.log(
      hasError,
      location.pathname !== `/${merchantname}/cart`,
      "checkhaserror"
    );
    if (
      hasError &&
      (location.pathname !== `/${merchantname}/userdetails` ||
        location.pathname !== `/${merchantname}/cart` ||
        location.pathname !== `/${merchantname}/checkout` ||
        location.pathname !== `/${merchantname}` ||
        location.pathname !== `/${merchantname}/product-detail/${id}` ||
        location.pathname !== `/${merchantname}/login` ||
        location.pathname !== `/${merchantname}/signup`)
    ) {
      setShowSearchForm(false);
    }
  }, [location.pathname, merchantname, hasError]);
  useEffect(() => {
    if (pause === "1") {
      setShowAlert(true);
    }
  }, [pause]);

  const closeAlert = () => {
    setShowAlert(false);
  };
  
  const renderMagnifyingGlassIcon = () => {
    return (
      <svg
        width={22}
        height={22}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 22L20 20"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const renderSearchForm = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          navigate(`/${merchantname}`);
        }}
        className="flex-1 py-2 text-slate-900 dark:text-slate-100"
      >
        <div className="bg-slate-50 dark:bg-slate-800 flex items-center space-x-1.5 px-5 h-full rounded">
          {renderMagnifyingGlassIcon()}
          <input
            ref={inputRef}
            type="text"
            onChange={(e) => setSearchProduct(e.target.value)}
            value={searchProduct}
            placeholder="Type and press enter"
            className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-base"
            autoFocus
          />
          <button
            type="button"
            onClick={() => {
              setSearchProduct("");
              setShowSearchForm(false);
            }}
          >
            <XMarkIcon className="w-5 h-5" />
          </button>
        </div>
        <input type="submit" hidden value="" />
      </form>
    );
  };
  console.log(onlineData, pauselabel, showAlert, "onlineData");
  const renderContent = () => {
    return (
      <div>
        {showAlert && (
          <div className="fixed top-0 left-1/2 transform -translate-x-1/2 mt-4 z-50 w-full max-w-md px-4">
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <span className="block sm:inline"> {pauselabel}</span>
              <button
                onClick={closeAlert}
                className="absolute top-0 bottom-0 right-0 px-4 py-3"
              >
                <svg
                  className="fill-current h-6 w-6 text-red-500"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <title>Close</title>
                  <path d="M14.348 5.652a1 1 0 10-1.414-1.414L10 7.586 7.066 4.652a1 1 0 10-1.414 1.414L8.586 10l-2.934 2.934a1 1 0 101.414 1.414L10 12.414l2.934 2.934a1 1 0 101.414-1.414L11.414 10l2.934-2.934z" />
                </svg>
              </button>
            </div>
          </div>
        )}
        <div className="h-20 flex justify-between">
          <div className="flex items-center lg:hidden flex-1">
            <MenuBar />
          </div>
          <div className="hidden lg:flex-1 lg:flex items-center">
            <Logo className="flex-shrink-0" />
          </div>

          <div className="flex lg:hidden sm:flex sm:items-center lg:flex-1  justify-center mx-0 my-4">
            {showSearchForm ? (
              renderSearchForm()
            ) : (
              <div className="lg:flex items-center justify-center w-full ">
                <Logo className="w-12 h-auto" />
              </div>
            )}
          </div>

          <div className="flex-[2] hidden lg:flex justify-center mx-4">
            {showSearchForm ? renderSearchForm() : <Navigation />}
          </div>
          <div className="flex-1 flex items-center justify-end text-slate-700 dark:text-slate-100">
            {/* {pause === "1" ? (
            <p className="text-sm font-bold text-red-600 mr-2">
              Online ordering is currently unavailable
            </p>
          ) : (
            <></>
          )} */}
         <div className="lg:flex">
           <Sidepanel />
         </div>
            <div className="hidden lg:flex">
              <button
                className="flex w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none items-center justify-center "
                onClick={() => navigate(`/${merchantname}`)}
              >
                <BiFoodMenu size={22} />
              </button>
            </div>
            {(location.pathname === `/${merchantname}/userdetails` ||
              location.pathname === `/${merchantname}/cart` ||
              location.pathname === `/${merchantname}/checkout` ||
              location.pathname !== `/${merchantname}/product-detail/${id}` ||
              location.pathname === `/${merchantname}`) && (
              <button
                className="flex w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none items-center justify-center"
                onClick={() => setShowSearchForm(!showSearchForm)}
              >
                {renderMagnifyingGlassIcon()}
              </button>
            )}

            <div className="hidden lg:flex">
              <AvatarDropdown />
            </div>
            <CartDropdown categoryData={categoryData} />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="nc-MainNav2Logged relative z-10 bg-white dark:bg-neutral-900 border-b border-slate-100 dark:border-slate-700">
      <div className="container ">{renderContent()}</div>
    </div>
  );
};
export default MainNav2Logged;
