import { FC, useCallback, useEffect, useState } from "react";
import HeaderFilterSection from "components/HeaderFilterSection";
import ProductCard from "components/ProductCard";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { CategoryData, Product, menuSchedule } from "data/data";
import { apiGetItemData } from "services/MenuService";
import { getAllCategoryNameData } from "services/HomeService";
import { useCartDataProvider } from "../../contaxt/CartProductContext";
//import aboutHeroRight from "/images/about-hero-right.png";
import { productImgs } from "contains/fakeData";
import myimg from "images/about-hero-right.png";
import myrealimg from "images/default-pasta.jpg";
import { getMenuScheduleData } from "services/HomeService";
import Page404 from "../Page404/Page404";
import { useNavigate } from "react-router-dom";
export interface PageHomeMenuProps {
  data?: Product[];
}
interface OnlineOrderSettings {
  logo?: string;
  orderLeadTime?: number;
  image?: string;
  titleText?: string;
  customTextOne?: string;
  pauseOnlineOrder?: boolean;
  pauseOnlineOrderLabel?: string;
  onlineDeliveryId?: string;
  isDelivery?: boolean;

  // Add other properties if necessary
}
interface ResponseData {
  onlineOrderSettings: OnlineOrderSettings;
  items: any[];
}
const PageHomeMenu: FC<PageHomeMenuProps> = () => {
  const [itemData, setItemData] = useState<Product[]>([]);
  const [displayedItems, setDisplayedItems] = useState<number>(10);
  const [categoryData, setCategoryData] = useState<CategoryData[]>([]);
  const [menuScheduleData, setMenuScheduleData] = useState<menuSchedule[]>([]);
  const [isMobile, setIsMobile] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { searchProduct }: any = useCartDataProvider();
  const [loading, setLoading] = useState(true);
  const [onlineOrderSettings, setOnlineOrderSettings] = useState<OnlineOrderSettings | null>(null);

  const imageUrl = myrealimg;
  console.log({ imageUrl }, "imageUrl111111");
  // Function to check if the viewport width is within the mobile range
  const checkIsMobile = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  // Run the checkIsMobile function when the component mounts and when the window is resized
  useEffect(() => {
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);
  // const getItemData = async () => {
  //   try {
  //     const res = await apiGetItemData();
  //     const data = res.data as any;
  //     console.log(data.items, "data")
  //     const orderFee = data.onlineOrderSettings.onlineOrderFee;
  // localStorage.setItem('orderFee', orderFee);
  //     const items = data.items.map((items: any) => items.items);
  //     const flattenedItems = items.flatMap((innerArray: any) => innerArray);
  //     setItemData(flattenedItems as Product[]);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // useEffect(() => {
  //   getItemData();
  // }, []);
  // const renderAllData = () => {
  //   getItemData();
  // };
  const getAllCategoryName = async () => {
    try {
      const allCategoryRes: { data: { items: any[] } } =
        await getAllCategoryNameData();
      const menuSchedule: any = await getMenuScheduleData();
      console.log(
        allCategoryRes?.data?.items.length === 0,
        menuSchedule.data,
        localStorage,
        "categorydata11111"
      );
      if (allCategoryRes?.data?.items.length != 0) {
      const MERCHANT_STORAGE_KEY = "merchantname";
      const newmwechantname = localStorage.getItem(MERCHANT_STORAGE_KEY) ?? "";
      localStorage.setItem("merchantname", newmwechantname);
      setCategoryData(allCategoryRes.data?.items as CategoryData[]);
      setMenuScheduleData(menuSchedule.data as menuSchedule[]);
      } else {
        console.log(hasError, "hasError")
        setHasError(true);
        localStorage.removeItem("merchantname");
      }
    } catch (error) {
      console.log(error);
       setHasError(true);
    }
  };
  const fetchSettings = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getAllCategoryNameData();
      const obtainedData = (response.data as ResponseData)?.onlineOrderSettings;
      const itemData = (response.data as ResponseData)?.items;

      if (itemData.length === 0) {
        setHasError(true);
      } else {
        const onlineData = obtainedData as OnlineOrderSettings;
        setOnlineOrderSettings({
          ...onlineData,
          customTextOne: onlineData.customTextOne ?? "",
        });
        if (onlineData?.logo) {
                  localStorage.setItem("onlineOrderSettingsLogo", onlineData?.logo);
                }
                if (onlineData?.orderLeadTime) {
                  localStorage.setItem(
                    "onlineOrderSettingsLeadTime",
                    onlineData?.orderLeadTime.toString()
                  );
        }
         if (onlineData?.orderLeadTime) {
           localStorage.setItem(
             "onlineOrderSettingsLeadTime",
             onlineData?.orderLeadTime.toString()
           );
         }
                if (onlineData?.pauseOnlineOrder) {
                  localStorage.setItem(
                    "onlineOrderSettingsPause",
                    onlineData?.pauseOnlineOrder.toString()
                  );
                }
                if (onlineData?.pauseOnlineOrderLabel) {
                  localStorage.setItem(
                    "onlineOrderSettingsPauseLabel",
                    onlineData?.pauseOnlineOrderLabel.toString()
                  );
                  }
                   if (onlineData?.onlineDeliveryId) {
                     localStorage.setItem(
                       "onlineDeliveryId",
                       onlineData?.onlineDeliveryId.toString()
                     );
                  }
                   if (onlineData?.isDelivery) {
                     localStorage.setItem(
                       "isDelivery",
                       onlineData?.isDelivery.toString() ?? "false"
                     );
                   }
      }
    } catch (error) {
      console.error("Error fetching online order settings:", error);
      setHasError(true);
    } finally {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    const fetchData=async()=>{
      await getAllCategoryName();
      await fetchSettings()
    }
    fetchData()
  }, []);
 
  // if (hasError === true) {
  //    console.log(hasError, "haserror");
  //    return <Page404 />;
  // }
  const navigate = useNavigate();
  useEffect(() => {
     console.log(hasError, "hasError");
     const MERCHANT_STORAGE_KEY = "merchantname";
    
    const merchantname = localStorage.getItem(MERCHANT_STORAGE_KEY);
    if (hasError === true) {
      navigate(`/${merchantname}/Page404`);
    }
   }, [hasError===true]);
 
  const renderMoreData = () => {
    setDisplayedItems(itemData.length);
  };
  const renderLessData = () => {
    setDisplayedItems(10);
  };
  const readytoDataLess = displayedItems === itemData.length;
  return (
    <div className="nc-PageHomeMenu relative">
      <HeaderFilterSection
        itemData={itemData}
        menuScheduleData={menuScheduleData}
        // getItemData={getItemData}
        searchProduct={searchProduct}
        setItemData={setItemData}
        // renderAllData={renderAllData}
        categoryData={categoryData}
        imageUrl={imageUrl}
        onlineOrderSettings={onlineOrderSettings}
        loading={loading}
      />
      <div
        className={`grid gap-4 sm:gap-8 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5  `}
      >
        {itemData
          .filter((item) =>
            item.name.toLowerCase().includes(searchProduct.toLowerCase())
          )
          .slice(0, displayedItems)
          .map((item: Product, index: number) => (
            <ProductCard categoryData={categoryData} data={item} key={index} />
          ))}
      </div>
      <div className="flex mt-16 justify-center items-center">
        {itemData.length > 10 ? (
          <ButtonPrimary
            onClick={() =>
              readytoDataLess ? renderLessData() : renderMoreData()
            }
          >
            {`${readytoDataLess ? "Show less" : "Show me more"}`}
          </ButtonPrimary>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default PageHomeMenu;
