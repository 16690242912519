import React, { FC, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import LikeButton from "./LikeButton";
import Prices from "./Prices";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
import { CategoryData, Product, PRODUCTS } from "data/data";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import BagIcon from "./BagIcon";
import toast from "react-hot-toast";
import { Transition } from "@headlessui/react";
import ModalQuickView from "./ModalQuickView";
import ProductStatus from "./ProductStatus";
import { useCartDataProvider } from "contaxt/CartProductContext";
// import { useCartDataProvider } from "contaxt/CartProductContext";

export interface ProductCardProps {
  className?: string;
  data?: Product;
  isLiked?: boolean;
  categoryData?: CategoryData[];
}

const ProductCard: FC<ProductCardProps> = ({
  className = "",
  data = PRODUCTS[0],
  categoryData,
  isLiked,
}) => {
  const {
    name,
    price,
    onlinePrice,
    
    description ,
    sizes,
    variants,
    variantType,
    status,
    imageUrl,
    unit,
    note,
    priceType,
    isAvailable,
  } = data;
  const [variantActive, setVariantActive] = React.useState(0);
  const [showModalQuickView, setShowModalQuickView] = React.useState(false);
  const [productId, setProductId] = useState<number>();
  const [isMobile, setIsMobile] = useState(false);
  const MERCHANT_STORAGE_KEY = "merchantname";
  const merchantname = localStorage.getItem(MERCHANT_STORAGE_KEY);
  // Function to check if the viewport width is within the mobile range
  const checkIsMobile = () => {
    console.log(window.innerWidth, "check1");
    setIsMobile(window.innerWidth <= 767);
  };
  console.log(isMobile, "check1");

  // Run the checkIsMobile function when the component mounts and when the window is resized
  useEffect(() => {
    checkIsMobile();
    console.log(checkIsMobile, "check2");
    window.addEventListener("resize", checkIsMobile);
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);
  // const [productObj, setProductObj] = useState<Product | null>(null);
  const { dispatch } = useCartDataProvider();
  const navigate = useNavigate();

  const getProductCategoryNames = () => {
    if (!data) {
      return [];
    }
    console.log(data, "inview");
    const productId = data.id;
    const productCategory = categoryData?.find((category) =>
      category.items?.some((item) => item.id === productId)
    );
    const cateogryNames = productCategory ? productCategory.name : [];
    return cateogryNames;
  };
  const productCategoryNames = getProductCategoryNames();
  console.log(productCategoryNames, "productCategoryNames");

  const notifyAddTocart = ({ size }: { size?: string }) => {
    toast.custom(
      (t) => (
        <Transition
          appear
          show={t.visible}
          className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
          enter="transition-all duration-150"
          enterFrom="opacity-0 translate-x-20"
          enterTo="opacity-100 translate-x-0"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-20"
        >
          <p className="block text-base font-semibold leading-none">
            Added to cart!
          </p>
          <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
          {renderProductCartOnNotify({ size })}
        </Transition>
      ),
      { position: "top-right", id: "nc-product-notify", duration: 1000 }
    );
  };
console.log(isAvailable,"availablity")
  const renderProductCartOnNotify = ({ size }: { size?: string }) => {
    return (
      
        isAvailable === 2 || isAvailable === 3 ?
        (
          <div className="flex ">
          <div className="h-24 w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100 ">
            <img
              src={imageUrl}
              alt={name}
              className="h-full w-full object-cover object-center"
            />
          </div>

          <div className="ml-4 flex flex-1 flex-col">
            <div>
              <div className="flex justify-between ">
                <div>
                  <h3 className="text-base font-medium ">{name}</h3>
                  <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                    <span>{productCategoryNames}</span>
                    {/* <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                    <span>{isAvailable ? "Available" : "Unavailable"}</span> */}
                  </p>
                </div>
                <Prices onlinePrice={onlinePrice} className="mt-0.5" />
              </div>
            </div>
            <div className="flex flex-1 items-end justify-between text-sm">
              <p className="text-gray-500 dark:text-slate-400">Qty 1</p>

              <div className="flex">
                {/* <Link
                to={"/cart"}
                className="font-medium text-primary-5000 dark:text-primary-100 "
              >
                View cart
              </Link> */}
              </div>
            </div>
          </div>
          </div>
        )
        : null
      
    )
  }

  const getBorderClass = (Bgclass = "") => {
    if (Bgclass.includes("red")) {
      return "border-red-500";
    }
    if (Bgclass.includes("violet")) {
      return "border-violet-500";
    }
    if (Bgclass.includes("orange")) {
      return "border-orange-500";
    }
    if (Bgclass.includes("green")) {
      return "border-green-500";
    }
    if (Bgclass.includes("blue")) {
      return "border-blue-500";
    }
    if (Bgclass.includes("sky")) {
      return "border-sky-500";
    }
    if (Bgclass.includes("yellow")) {
      return "border-yellow-500";
    }
    return "border-transparent";
  };

  const renderVariants = () => {
    if (!variants || !variants.length || !variantType) {
      return null;
    }

    if (variantType === "color") {
      return (
        <div className="flex space-x-1">
          {variants.map((variant, index) => (
            <div
              key={index}
              onClick={() => setVariantActive(index)}
              className={`relative w-6 h-6 rounded-full overflow-hidden z-10 border cursor-pointer ${
                variantActive === index
                  ? getBorderClass(variant.color)
                  : "border-transparent"
              }`}
              title={variant.name}
            >
              <div
                className={`absolute inset-0.5 rounded-full z-0 ${variant.color}`}
              ></div>
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className="flex ">
        {variants.map((variant, index) => (
          <div
            key={index}
            onClick={() => setVariantActive(index)}
            className={`relative w-11 h-6 rounded-full overflow-hidden z-10 border cursor-pointer ${
              variantActive === index
                ? "border-black dark:border-slate-300"
                : "border-transparent"
            }`}
            title={variant.name}
          >
            <div className="absolute inset-0.5 rounded-full overflow-hidden z-0">
              <img
                src={variant.thumbnail}
                alt="variant"
                className="absolute w-full h-full object-cover"
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderGroupButtons = () => {
    return (
      <div className="absolute bottom-0 group-hover:bottom-4 inset-x-1 flex justify-center opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all">
        <ButtonPrimary
          className="shadow-lg"
          fontSize="text-xs"
          sizeClass="py-2 px-4"
          onClick={() => {
            notifyAddTocart({ size: "XL" });
            dispatch({ type: "ADD_TO_CART", payload: data });
          }}
        >
          <BagIcon className="w-3.5 h-3.5 mb-0.5" />
          <span className="ml-1">Add to bag</span>
        </ButtonPrimary>
        {/* <ButtonSecondary
          className="ml-1.5 bg-white hover:!bg-gray-100 hover:text-slate-900 transition-colors shadow-lg"
          fontSize="text-xs"
          sizeClass="py-2 px-4"
          onClick={() => {
            setShowModalQuickView(true);
            setProductId(data.id);
          }}
        >
          <ArrowsPointingOutIcon className="w-3.5 h-3.5" />
          <span className="ml-1">Quick view</span>
        </ButtonSecondary> */}
      </div>
    );
  };

  const renderSizeList = () => {
    if (!sizes || !sizes.length) {
      return null;
    }

    return (
      <div className="absolute bottom-0 inset-x-1 space-x-1.5 flex justify-center opacity-0 invisible group-hover:bottom-4 group-hover:opacity-100 group-hover:visible transition-all">
        {sizes.map((size, index) => {
          return (
            <div
              key={index}
              className="nc-shadow-lg w-10 h-10 rounded-xl bg-white hover:bg-slate-900 hover:text-white transition-colors cursor-pointer flex items-center justify-center uppercase font-semibold tracking-tight text-sm text-slate-900"
              onClick={() => notifyAddTocart({ size })}
            >
              {size}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    isAvailable === 2 || isAvailable === 3 ? (
      <>
        <div
          className={`nc-ProductCard relative flex flex-col bg-transparent ${className}`}
          data-nc-id="ProductCard"
        >
          <div
          // style={
          //   isMobile
          //     ? {
          //         display: "grid", // Use grid layout for mobile
          //         gridTemplateColumns: "repeat(2, 1fr)", // Two columns
          //         gap: "2px",
          //       }
          //     : {}
          // }
          // className={isMobile ? "grid grid-cols-2" : ""}
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/${merchantname}/product-detail/${data.id}`);
              }}
            >
              <div className="flex flex-col item-center md:item-start ">
                <div className="bg-[#ddd] h-[130px] md:h-[224px] w-[130px] md:w-[224px] rounded-3xl flex items-center justify-center mx-auto mb-3 md:mb-0">
                  <div className="group h-full w-full relative cursor-pointer">
                     
                    <NcImage
                      containerClassName="flex aspect-w-1 aspect-h-1 w-full h-0" // Adjust aspect-w and aspect-h values
                      src={imageUrl?imageUrl:''}
                      className="object-cover w-full h-full drop-shadow-lg rounded-3xl"
                    />
                    <div className="absolute bottom-1 sm:bottom-2 hidden justify-center w-full group-hover:flex">
                      <div className="cursor-pointer text-black bg-[#fff] sm:px-5 px-2 sm:py-1 rounded-md text-[8px] sm:text-base shadow-md">
                        View
                      </div>
                    </div>
                  </div>
                  <ProductStatus status={status} />
                </div>


                <div className="space-y-1 px-2.5 pt-2 pb-1">
                  {renderVariants()}
                  <div className="text-center">
                    
                    <div className="font-semibold text-sm pt-1"> {/* Decreased font size */}
                      {name}
                     
                    </div>
                    <div className="text-xs font-semibold pt-0"> {/* Decreased font size */}
                      <Prices onlinePrice={onlinePrice} />
                    </div>
                     <div className=" text-sm text-gray-500"> {/* Decreased font size */}
                      {description}
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* QUICKVIEW */}
        <ModalQuickView
          categoryData={categoryData}
          show={showModalQuickView}
          onCloseModalQuickView={() => setShowModalQuickView(false)}
          productId={productId}
        />
      </>
    ) : null
  );
};

export default ProductCard;
