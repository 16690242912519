const CartReducer = (state: any, action: any) => {
  switch (action.type) {
    case "ADD_TO_CART":
      const existingItemIndex = state.Products.findIndex(
        (item: any) =>
          item.id === action.payload.id &&
          JSON.stringify(item.modifierChecked) ===
            JSON.stringify(action.payload.modifierChecked)
      );

      if (existingItemIndex >= 0) {
        const updatedProducts = state.Products.map(
          (item: any, index: number) => {
            if (index === existingItemIndex) {
              return {
                ...item,
                quantity: item.quantity + (action.payload.quantity || 1),
              };
            }
            return item;
          }
        );

        return {
          ...state,
          Products: updatedProducts,
        };
      } else {
        const newProduct = {
          ...action.payload,
          quantity: action.payload.quantity || 1,
          cartItemId: Date.now(),
        };
        const updatedProducts = [...state.Products, newProduct];

        return {
          ...state,
          Products: updatedProducts,
        };
      }

    case "EDIT_CART":
      const { modifierChecked, cartItemId, quantity, note } = action.payload;
      const updatedProducts = state.Products.map((item: any) => {
        if (item.cartItemId === cartItemId) {
          return {
            ...item,
            modifierChecked: modifierChecked,
            quantity: quantity,
            note: note,
          };
        }
        return item;
      });

      return {
        ...state,
        Products: updatedProducts,
      };

    case "REMOVE_ITEMS":
      const filterProduct = state.Products.filter((items: any) => {
        return items.cartItemId !== action.payload.cartItemId;
      });
      return {
        ...state,
        Products: filterProduct,
      };

    case "HANDLE_DECREMENT":
      const updatedDataDec = state.Products.map((item: any) => {
        if (
          item.cartItemId === action.payload.cartItemId &&
          item.quantity > 1
        ) {
          return { ...item, quantity: item.quantity - 1 };
        }
        return item;
      });

      return {
        ...state,
        Products: updatedDataDec,
      };

    case "HANDLE_INCREMENT":
      const updatedDataInc = state.Products.map((item: any) => {
        if (item.cartItemId === action.payload.cartItemId) {
          return { ...item, quantity: item.quantity + 1 };
        }
        return item;
      });
      return {
        ...state,
        Products: updatedDataInc,
      };
    case "CLEAR_CART":
      return {
        ...state,
        Products: [], // or whatever your initial state for the cart should be
      };
    // other cases...
   
    case "TOTAL_PRICE":
      const totalprice = state.Products.reduce((accum: any, ele: any) => {
        return accum + ele.quantity * ele.onlinePrice;
      }, 0);
      return {
        ...state,
        total_price: totalprice,
      };

    default:
      return { ...state };
  }
};

export default CartReducer;
