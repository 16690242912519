import React from 'react';
import { Product } from "data/data";// Adjust the import according to your project structure
import NcInputNumber from "components/NcInputNumber"; // Adjust the import according to your project structure
import Prices from "components/Prices"; // Adjust the import according to your project structure
import { MdOutlineModeEdit, MdOutlineDeleteOutline } from 'react-icons/md';
// import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCartDataProvider } from "../../contaxt/CartProductContext";
interface RenderProductProps {
  item: Product;
  index: number;
  calculatingTotalPrice: (item: Product) => number;
  merchantname: string;
}

const RenderProduct: React.FC<RenderProductProps> = ({
    item,
    index,
    calculatingTotalPrice,
    merchantname,
}) => {
   // const dispatch = useDispatch();
    const navigate = useNavigate();
   const {  dispatch  }: any = useCartDataProvider();
    const {
        imageUrl,
        price,
        onlinePrice,
        name,
        id,
        note,
        isAvailable,
        quantity,
        modifierChecked,
        modifierSets,
        cartItemId
    } = item;
    const handleQuantityChange = (newQuantity: number) => {
        const updatedItem = { ...item, quantity: newQuantity };
      };
    return (
      <div
        key={index}
        className="relative flex py-4 sm:py-5 xl:py-6 first:pt-0 last:pb-0"
      >
        <div className="ml-2 sm:ml-3 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div className="flex-[1.5] ">
                <h3 className="text-base font-semibold">{name}</h3>
                <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
                  <div className="flex items-center space-x-1.5"></div>
                  <span className="mx-4 border-l border-slate-200 dark:border-slate-700 "></span>
                  <div className="flex items-center space-x-1.5"></div>
                </div>

                <div className="mt-3 flex justify-between w-full sm:hidden relative">
                  <div className="sm text-center relative">
                    <NcInputNumber
                      className="relative z-10"
                      quantity={quantity}
                      id={id}
                      modifierChecked={modifierChecked}
                      item={item}
                      onChange={handleQuantityChange}
                    />
                  </div>
                  <Prices
                    contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-base font-semibold h-full"
                    onlinePrice={calculatingTotalPrice(item)}
                  />
                </div>
              </div>

              <div className="hidden sm:block text-center relative">
                <NcInputNumber
                  className="relative z-10"
                  quantity={quantity}
                  id={id}
                  modifierChecked={modifierChecked}
                  item={item}
                  onChange={handleQuantityChange}
                />
              </div>

              <div className="hidden flex-1 sm:flex justify-end text-base font-semibold">
                <Prices
                  onlinePrice={calculatingTotalPrice(item)}
                  className="mt-0.5"
                />
              </div>
            </div>
          </div>

          {modifierSets?.map((item) => {
            const matchingKey =
              modifierChecked &&
              Object.keys(modifierChecked)?.find(
                (key) => Number(key) === item.id
              );
            if (matchingKey) {
              let totalPrice = 0;
              item.modifierSetModifierItems?.forEach((modifierItem) => {
                const modifierItemId = modifierItem.id.toString();
                if (modifierChecked[matchingKey]?.[modifierItemId]) {
                  totalPrice += modifierItem.modifierItem.onlinePrice;
                }
              });
              return (
                <div key={item.id} className="flex flex-col">
                  {item.modifierSetModifierItems?.some((modifierItem) =>
                    Object.entries(modifierChecked[matchingKey]).some(
                      ([key, value]) =>
                        value === true && modifierItem.id === Number(key)
                    )
                  ) && <h1 className="text-sm font-bold"></h1>}
                  <div className="flex flex-wrap mt-2">
                    {item.modifierSetModifierItems?.map((modifierItem) => {
                      return (
                        <React.Fragment key={modifierItem.id}>
                          {Object.entries(modifierChecked[matchingKey])
                            .filter(([_, value]) => value === true)
                            .map(([innerKey]) => {
                              if (modifierItem.id === Number(innerKey)) {
                                if (
                                  modifierItem.modifierItem.onlinePrice === 0
                                ) {
                                  return (
                                    <h3
                                      key={innerKey}
                                      className="text-xs mr-1 "
                                    >
                                      {modifierItem.modifierItem.name},
                                    </h3>
                                  );
                                }
                              }
                              return null;
                            })}
                        </React.Fragment>
                      );
                    })}
                  </div>
                  <div className="mt-2">
                    {item.modifierSetModifierItems?.map((modifierItem) => {
                      return (
                        <React.Fragment key={modifierItem.id}>
                          {Object.entries(modifierChecked[matchingKey])
                            .filter(([_, value]) => value === true)
                            .map(([innerKey]) => {
                              if (modifierItem.id === Number(innerKey)) {
                                if (
                                  modifierItem.modifierItem.onlinePrice != 0
                                ) {
                                  return (
                                    <h3
                                      key={innerKey}
                                      className=" text-xs flex justify-between pb-2"
                                    >
                                      <span>
                                        {modifierItem.modifierItem.name}
                                      </span>
                                      <span className=" text-slate-900 dark:text-slate-200">
                                        ${modifierItem.modifierItem.onlinePrice}
                                      </span>
                                    </h3>
                                  );
                                }
                              }
                              return null;
                            })}
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              );
            }
            return null;
          })}
          {note && (
            <div className="mt-4">
              <h3 className="text-sm font-bold">Item Note:</h3>
              <p className="text-xs text-slate-600 dark:text-slate-300 mt-1">
                {note}
              </p>
            </div>
          )}

          <div className="flex mt-auto pt-4 items-end justify-between text-sm w-full">
            {/* {isAvailable ? renderStatusInstock() : renderStatusSoldout()} */}
            <div className="flex ml-auto">
              <div
                onClick={() =>
                  navigate(`/${merchantname}/product-detail/${id}`, {
                    state: {
                      modifierChecked: modifierChecked,
                      cartItemId: cartItemId,
                          quantity: quantity,
                      note: note,
                    },
                  })
                }
                className="mr-3 cursor-pointer"
              >
                <MdOutlineModeEdit style={{ color: "#7BB542", fontSize: 20 }} />
              </div>
              <div
                onClick={() =>
                  dispatch({
                    type: "REMOVE_ITEMS",
                    payload: item,
                  })
                }
                className="cursor-pointer"
              >
                <MdOutlineDeleteOutline
                  style={{ color: "#7BB542", fontSize: 20 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
export default RenderProduct;